/*
  Angular service to manage a tab session. When creating a new tab session,
  we will generate a unique tabId and store this in sessionStorage,
  which is data that is not shared between tabs.

  There are several scenarios we need to handle:
  1. User hits portal for first time (or clears cache)
    -- We generate a new tabId and store this in sessionStorage

  2. User opens a new empty tab and navigates to portal
  3. User cmd+clicks on a link to open a new tab
  -- 2 and 3 are the same, we generate a new tabId and store this in sessionStorage
  -- we also copy over the bootstrapTabId from the activeTab in localStorage

  4. User uses the "Duplicate Tab" feature in chrome
  -- We need to detect that we are a duplicated tab, and generate a new tabId
  -- but also set the bootstrapTabId to the activeTab in localStorage

  5. Refreshing the page of existing tab
  -- We read the tabId from sessionStorage and use this

  For new tabs, we generate a new tabId and store this in sessionStorage.
  We want it in sessionStorage, so we can handle the "Refreshing the page of existing tab" scenario.

  To handle opening up a new tab and navigating to the portal or cmd+clicking on a link to open a new tab,
  we need to store the activeTab in localStorage. Anytime the window gets focus, we will set the activeTab
  to the current tabId. So on bootup, if we don't already have a tabId in our sessionStorage, we will still
  generate a new one, but we also set our bootstrapTabId to the activeTab. This gets sent to the backend
  in a header (x-bootstrap-tab-id) so the backend can bootstrap the tab session data. This is only needed
  once, so once the backend has bootstrapped the tab session data, we no longer need to send the header. We
  set the isBootstrapped flag in sessionStorage to true, so we know not to send the header anymore.


  We were running into issues with chrome where we set the tabId in sessionStorage, but this got
  wiped out during the bootstrap of the application. So we need to move to where we flush it to
  local storage whwn the page is being unloaded (or perhaps we will do it when we detect it is nulled out).

*/
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TabSessionService {
  private tabId: string;
  private bootstrapTabId: string;
  private isBootstrapped: boolean;

  constructor() {
    const activeTab = localStorage.getItem('activeTab');
    this.tabId = sessionStorage.getItem('tabId');
    this.bootstrapTabId = sessionStorage.getItem('bootstrapTabId');
    this.isBootstrapped = sessionStorage.getItem('isTabBootstrapped') === 'true';

    const isFromDuplicateTab = activeTab && activeTab === this.tabId;

    // If being loaded for the first time, or if
    // we are coming from the "Duplicate Tab" feature
    // of chrome, we need to generate a new tabId
    if (!this.tabId || isFromDuplicateTab) {
      this.tabId = uuidv4();
      this.bootstrapTabId = activeTab;
      this.isBootstrapped = false;
      this.flushTabSessionData();
    }

    if (!document.hidden) {
      // If we are the active tab, we need to set the activeTab
      // since the focus handler isn't initialized yet.
      this.setActiveTabInLocalStorage();
    }

    this.initializeSetActiveTabOnFocusHandler();
    this.initializeRemoveActiveTabOnBeforeUnloadHandler();
    this.initializeFlushTabSessionDataOnUnloadHandler();
  }

  getTabId() {
    return this.tabId;
  }

  // We conditionally are setting a x-bootstrap-tab-id header in the interceptor
  // We only need to send this header once, and once the tab session data is
  // bootstrapped on the backend, we no longer need to send the header (even
  // though there is no harm in doing so)
  getBoostrapTabId() {
    const currentBootstrapId = this.bootstrapTabId;
    const bootstrapTabId = currentBootstrapId || localStorage.getItem('activeTab');
    if (!currentBootstrapId) {
      this.bootstrapTabId = bootstrapTabId;
      sessionStorage.setItem('bootstrapTabId', bootstrapTabId);
    }

    return this.isBootstrapped ? null : bootstrapTabId;
  }

  setIsBootstrapped() {
    this.isBootstrapped = true;
    sessionStorage.setItem('isTabBootstrapped', 'true');
  }

  private initializeSetActiveTabOnFocusHandler() {
    // The document:visibilitychange wan't enough to use to set the
    // current active tab, as it doesn't fire if there are two browser
    // windows open, and the user clicks back and forth between them.
    // the window:focus seems to do the trick.
    // TODO: we need to double check that if the window is already focused
    // when we register this, to make sure we are setting the activeTab
    window.addEventListener('focus', () => {
      this.setActiveTabInLocalStorage();
    }, true);
  }

  private initializeRemoveActiveTabOnBeforeUnloadHandler() {
    // When reloading the page, we are no longer the active
    // tab, so we need to remove the activeTab from localStorage
    window.addEventListener('beforeunload', (event) => {
      // If we are the active tab, we need to remove the activeTab
      // so that when we reload the page, we don't detect that we came
      // from a duplicate tab and regenerate the tabId.
      const activeTab = localStorage.getItem('activeTab');
      if (activeTab === this.tabId) {
        localStorage.removeItem('activeTab');
      }
    });
  }

  // We were running into issues with chrome where we set the tabId in sessionStorage, but this got
  // wiped out during the bootstrap of the application. So we need to move to where we flush it to
  // local storage whwn the page is being unloaded (or perhaps we will do it when we detect it is nulled out).
  private initializeFlushTabSessionDataOnUnloadHandler() {
    window.addEventListener('unload', () => {
      this.flushTabSessionData();
    });
  }

  private setActiveTabInLocalStorage() {
    localStorage.setItem('activeTab', this.tabId);
  }

  private flushTabSessionData() {
    sessionStorage.setItem('tabId', this.tabId);
    sessionStorage.setItem('bootstrapTabId', this.bootstrapTabId);
    sessionStorage.setItem('isTabBootstrapped', this.isBootstrapped.toString());
  }

}
