export interface Branding {
  brand: string;
  appName: string;
  title: string;
  baseUrl: string;
  toolbarColor: string;
  logoUrl?: string;
  printableLogoUrl?: string;
  profileLink?: string;
  ssoLoginLink?: string;
  ssoEnabled?: boolean;
  logoLinkUrl?: string;
  logoutUrl?: string;
  partnerHomepage: boolean;
  partnerPortingDashboard: boolean;
  manualPortOrdersSupported: boolean;
  partnerSupportGuideUrl?: string;
  customerSupportGuideUrl?: string;
  customerE911GuideUrl?: string;
  quotingSupportPhone: string;
  quotingSupportEmail: string;
  portingSupportPhone: string;
  portingSupportEmail: string;
  technicalSupportPhone: string;
  technicalSupportEmail: string;
}

export function getBrandFromHost(hostname: string, deployEnv: string) {
  if ( deployEnv === 'northwell' ) {
    return 'northwell';
  } else if (hostname.match(/(nec|univerge)/)) {
    return 'nec';
  } else if (hostname.match(/nuso/)) {
    return 'nuso';
  } else {
    return 'marketspace';
  }
}
