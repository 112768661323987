import { PortalAppEnvironment } from '@nuso/portal/environments';

export const defaults: PortalAppEnvironment = {
  appName: 'Management Portal',
  envName: 'changeme',
  production: true,
  sentry: '',
  showDebug: false,
  necSsoEnabled: false,
  necSsoLoginUrl: '',
  statusPageIoEmbedUrl: 'https://wkkzjngtxqk9.statuspage.io/embed/frame', // sandbox status page

  isTestDeleteMeta: true,
  customerTestingEnabled: false,
  nocEnabled: true,
  eFaxEnabled: true,
  ordersEnabled: true,
  cdrReportingEnabled: true,
  billingEnabled: false,
  portingEnabled: true,
  supportEnabled: true,
  liveTeamworkSupportEnabled: false,
  salesforceSupportEnabled: false,
  archivedSupportEnabled: false,
  billableEventsEnabled: true,
  issuesEnabled: true,
  statusPageEnabled: true,
  numberMovingEnabled: true,
  bandwidthSyncingEnabled: true,
  commportalUrl: 'https://control.nuso.cloud',
  bridgeZoomAuthUrl: 'https://TODO.nuso.io/auth',

  supportedCustomerFeatures: [
    'reports',
    'auditing', // View Audit Event Logs,
    'simple-auto-attendant',
    'shared-voicemail',
  ],
  serviceOfferingOptions: [
    { value: 'cloud-uc', label: 'NUSO Cloud UC' },
    { value: 'nuso-sip', label: 'NUSO SIP' },
    { value: 'blue-sip', label: 'NUSO Blue SIP' },
    { value: 'ipfax', label: 'IP Fax' },
    { value: 'green-uc', label: 'NUSO Green UC' },
    { value: 'teams-dr', label: 'Teams Direct Routing' },
    { value: 'nec-uc', label: 'NEC Univerge Cloud' },
    { value: 'nec-uc-m', label: 'NEC UCaaS(m)' },
    { value: 'nec-sip', label: 'NEC SIP' },
    { value: 'sotel-sip', label: 'NUSO SIP Legacy' },
  ],

  userRoleOptions: [
    { value: 'partner' },
    { value: 'customer' },
    { value: 'customer-admin' },
    { value: 'admin' },
    { value: 'superuser' },
    { value: 'end-user' },
    { value: 'porting-admin' },
    { value: 'sales-admin' },
    { value: 'noc-admin' },
  ]
};
