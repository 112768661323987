export * from './lib/portal-state.module';
export * from './lib/reducers/index';


export { getCurrentCustomer } from './lib/reducers/index';
export { SessionInfo } from './lib/reducers/login';
import * as fromRoot from './lib/reducers';
export { State as PortalState } from './lib/reducers';
import * as page from './lib/actions/page.actions';
import * as ui from './lib/actions/ui.actions';
import * as myCustomers from './lib/actions/my-customers.actions';
import * as login from './lib/actions/login.actions';
export { createReducers } from './lib/reducers/index';
export { SetCurrentCustomerGQL } from './lib/effects/login.effects';
export * as fromRoot from './lib/reducers';
export * as login from './lib/actions/login.actions';

export const PortalStore = {
  fromRoot,
  page,
  ui,
  login,
  myCustomers
};
