import { Action } from '@ngrx/store';

export interface CrudActionTypes {
  LOAD_ALL:         string;
  LOAD_ALL_SUCCESS: string;
  LOAD_ALL_FAIL:    string;
  UPDATE_FILTER:    string;
  CREATE:           string;
  UPDATE:           string;
  CREATED:          string;
  UPDATED:          string;
  CREATE_FAILED:    string;
  UPDATE_FAILED:    string;
  DELETE:           string;
  DELETED:          string;
  DELETE_FAILED:    string;
  SET_CURRENT:      string;
  RESET_CREATED:    string;
  RESET_UPDATED:    string;
  RESET_DELETED:    string;
};

export class ConcreteCrudActionTypes implements CrudActionTypes {
  LOAD_ALL:         string;
  LOAD_ALL_SUCCESS: string;
  LOAD_ALL_FAIL:    string;
  UPDATE_FILTER:    string;
  CREATE:           string;
  UPDATE:           string;
  CREATED:          string;
  UPDATED:          string;
  CREATE_FAILED:    string;
  UPDATE_FAILED:    string;
  DELETE:           string;
  DELETED:          string;
  DELETE_FAILED:    string;
  SET_CURRENT:      string;
  RESET_CREATED:    string;
  RESET_UPDATED:    string;
  RESET_DELETED:    string;

  constructor(label: string) {
    this.LOAD_ALL =         `[${label}] Load All`;
    this.LOAD_ALL_SUCCESS = `[${label}] Load All Success`;
    this.LOAD_ALL_FAIL =    `[${label}] Load All Fail`;
    this.UPDATE_FILTER =    `[${label}] Update Filter`;
    this.CREATE =           `[${label}] Create New`;
    this.UPDATE =           `[${label}] Update Existing`;
    this.CREATED =          `[${label}] Created`;
    this.UPDATED =          `[${label}] Updated`;
    this.CREATE_FAILED =    `[${label}] Create Failed`;
    this.UPDATE_FAILED =    `[${label}] Update Failed`;
    this.DELETE =           `[${label}] Delete`;
    this.DELETED =          `[${label}] Delete Success`;
    this.DELETE_FAILED =    `[${label}] Delete Failed`;
    this.SET_CURRENT =      `[${label}] Set Current`;
    this.RESET_CREATED =    `[${label}] Reset Created`;
    this.RESET_UPDATED =    `[${label}] Reset Updated`;
    this.RESET_DELETED =    `[${label}] Reset Deleted`;
  }
}

export class CrudActions<T> {
  createLoadAllAction: () => Action;
  createLoadAllSuccessAction: (payload: T[]) => Action;
  createLoadAllFailedAction: (payload: any) => Action;
  createUpdateFilterAction: (payload: string) => Action;
  createCreateAction: (payload: T) => Action;
  createUpdateAction: (payload: T) => Action;
  createCreateFailedAction: (payload: any) => Action;
  createUpdateFailedAction: (payload: any) => Action;
  createCreatedAction: (payload: T) => Action;
  createUpdatedAction: (payload: T) => Action;
  createDeleteAction: (payload: string) => Action;  // ID of the entity to be deleted
  createDeletedAction: (payload: string) => Action;
  createDeleteFailedAction: (payload: any) => Action;
  createSetCurrentAction: (payload: any) => Action;
  createResetCreatedAction: () => Action;
  createResetUpdatedAction: () => Action;
  createResetDeletedAction: () => Action;
}

export function createCrudActionTypes(label: string): CrudActionTypes {
  return {
    LOAD_ALL:         `[${label}] Load All`,
    LOAD_ALL_SUCCESS: `[${label}] Load All Success`,
    LOAD_ALL_FAIL:    `[${label}] Load All Fail`,
    UPDATE_FILTER:    `[${label}] Update Filter`,
    CREATE:           `[${label}] Create New`,
    UPDATE:           `[${label}] Update Existing`,
    CREATED:          `[${label}] Created`,
    UPDATED:          `[${label}] Updated`,
    CREATE_FAILED:    `[${label}] Create Failed`,
    UPDATE_FAILED:    `[${label}] Update Failed`,
    DELETE:           `[${label}] Delete`,
    DELETED:          `[${label}] Delete Success`,
    DELETE_FAILED:    `[${label}] Delete Failed`,
    SET_CURRENT:      `[${label}] Set Current`,
    RESET_CREATED:    `[${label}] Reset Created`,
    RESET_UPDATED:    `[${label}] Reset Updated`,
    RESET_DELETED:    `[${label}] Reset Deleted`,
  };
}

export function createCrudActions<T>(actionTypes: CrudActionTypes) {
  return {
    createLoadAllAction: () => ({ type: actionTypes.LOAD_ALL }),
    createLoadAllSuccessAction: (payload: T[]) => ({ type: actionTypes.LOAD_ALL_SUCCESS, payload }),
    createLoadAllFailedAction: (payload: any) => ({ type: actionTypes.LOAD_ALL_FAIL, payload }),
    createUpdateFilterAction: (payload: string) => ({ type: actionTypes.UPDATE_FILTER, payload }),
    createCreateAction: (payload: T) => ({ type: actionTypes.CREATE, payload }),
    createUpdateAction: (payload: T) => ({ type: actionTypes.UPDATE, payload }),
    createCreateFailedAction: (payload: any) => ({ type: actionTypes.CREATE_FAILED, payload }),
    createUpdateFailedAction: (payload: any) => ({ type: actionTypes.UPDATE_FAILED, payload }),
    createCreatedAction: (payload: T) => ({ type: actionTypes.CREATED, payload }),
    createUpdatedAction: (payload: T) => ({ type: actionTypes.UPDATED, payload }),
    createDeleteAction: (payload: string) => ({ type: actionTypes.DELETE, payload }),  // ID of the entity to be deleted
    createDeletedAction: (payload: string) => ({ type: actionTypes.DELETED, payload }),
    createDeleteFailedAction: (payload: any) => ({ type: actionTypes.DELETE_FAILED, payload }),
    createSetCurrentAction: (payload: string) => ({ type: actionTypes.SET_CURRENT, payload }),
    createResetCreatedAction: () => ({ type: actionTypes.RESET_CREATED }),
    createResetUpdatedAction: () => ({ type: actionTypes.RESET_UPDATED }),
    createResetDeletedAction: () => ({ type: actionTypes.RESET_DELETED }),
  };
}

/*

example:
  export const UserCrudActionTypes = createCrudActionTypes('User');
  export const UserCrudActions = createCrudActions<User>(UserCrudActionTypes);

*/
