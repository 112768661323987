import { gql } from 'apollo-angular';

export interface NetworkStatus {
  isConnected: boolean;
  isAuthenticationError: boolean;
}

export interface NetworkStatusState {
  networkStatus: NetworkStatus
}

export const NETWORK_STATUS_QUERY = gql`
  query networkStatus {
    networkStatus @client
  }
`;

