import { Injectable } from "@angular/core";
import { LoginStateService, LoginStatus } from './login-state.service';
import { LoginService } from './login.service';
import { tap, takeUntil, shareReplay, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { RavenErrorLoggingService, NotificationService } from '@nuso/common/util-errors';
import { Router } from '@angular/router';
import { GraphApiService, NetworkStatus, NETWORK_STATUS_QUERY, NetworkStatusUpdateService } from '@nuso/common/data-access-api';


@Injectable({
  providedIn: 'root'
})
export class LoginStateManagementService {
  private shutdown$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private loginState: LoginStateService,
    private errorLoggingService: RavenErrorLoggingService,
    private notificationService: NotificationService,
    private networkService: NetworkStatusUpdateService,
    private graphApi: GraphApiService
  ){
  }

  // on startup of the app, we see if we have an active session
  // we do this by loading a loginToken from local storage and sending it
  // to refreshToken api call
  init() {
    // NOTES: on initial app load, if we have a "loginToken" in local storage
    // then we need to kick off an initial refreshToken to see if the session is still valid

    // TODO: handle session expired (see login.effects.ts at bottom with NETWORK_STATUS_QUERY)

    // Clear out previous login errors when attempting new login
    this.loginState.onAttemptLogin.subscribe(() => {
      console.log('attempt login fired')
      this.notificationService.clearErrors()
    })

    // We need to setup listeners for when someone logs in, to redirect to redirectUrl
    this.loginState.onLogin.subscribe(status => {
      localStorage.setItem('loginToken', status.loginSession?.id);
      this.errorLoggingService.setUserContext(status.loginSession);
      console.log('on login', status)
      if (status.redirectUrl) {
        console.log('redirecting', status.redirectUrl);
        this.router.navigateByUrl(status.redirectUrl);
      }
    })

    this.loginState.onLogout.subscribe(status => {
      localStorage.removeItem('loginToken');
      this.errorLoggingService.resetUserContext();
      this.graphApi.apollo.client.clearStore();
      this.notificationService.clearErrors();
      // const { logoutUrl } = this.brandingService.getPrivateLabelBranding();
      // if ( logoutUrl ) {
      //   window.location.replace(logoutUrl);
      // } else {
      //   this.router.navigateByUrl('/', { replaceUrl: true });
      // }
      // cheating for now so as not to worry about state and redirects just refresh url to root
      // now when we do SSO, will be doing same thing but to url
      window.location.replace('/');
    })

    this.graphApi.watchQuery<NetworkStatus>({
      query: NETWORK_STATUS_QUERY
    }).pipe(
      filter(status => status.isAuthenticationError),
      tap(() => {
        this.loginState.receiveSessionInfo(null);
        this.networkService.updateAuthenticationError(false);
        this.notificationService.addError('Your login session is no longer valid, please login.')
        this.router.navigateByUrl('/login'); // not sure if best to go to /login or just /
      })
    ).subscribe();

    this.initialRefreshToken()
  }

  destroy() {
    this.shutdown$.next(true);
  }

  private initialRefreshToken() {
    // Maybe we move the state inside the login service...
    const loginToken = window.location.pathname === '/validate-token' ? null : localStorage.getItem('loginToken')
    this.loginState.refreshToken(loginToken)
    if (loginToken) {
      this.loginService.refreshToken(loginToken)
        .pipe(
          tap((res) => this.loginState.receiveSessionInfo(res)),
          takeUntil(this.shutdown$)
        )
        .subscribe();
    } else {
      this.loginState.receiveSessionInfo(null);
    }
  }
}