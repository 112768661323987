import { Action } from '@ngrx/store';

import { type } from '@nuso/common/util-state';
import { Customer } from '@nuso/orgs/types';

export const ActionTypes = {
  LOAD_CUSTOMERS: type('[My Customers] Load Customers'),
  LOAD_RETURNED: type('[My Customers] Load Returned'),
  LOAD_FAILED: type('[My Customers] Load Failed'),
  UPDATE_FILTER: type('[My Customers] Update Filter'),
  RESET_CUSTOMERS: type('[My Customers] Reset'),
};

export class LoadCustomersAction implements Action {
  type = ActionTypes.LOAD_CUSTOMERS;

  constructor() {}
}

export class LoadReturnedAction implements Action {
  type = ActionTypes.LOAD_RETURNED;

  constructor(public payload: Customer[]) {}
}

export class LoadFailedAction implements Action {
  type = ActionTypes.LOAD_FAILED;

  constructor(public payload: any) {}
}

export class UpdateFilterAction implements Action {
  type = ActionTypes.UPDATE_FILTER;

  constructor(public payload: string) {}
}

export class ResetCustomersAction implements Action {
  type = ActionTypes.RESET_CUSTOMERS;

  constructor() {}
}


export type Actions
  = LoadCustomersAction
    | LoadReturnedAction
    | LoadFailedAction
    | UpdateFilterAction
    | ResetCustomersAction;
