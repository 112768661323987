import { includes } from 'lodash';

import * as login from '../actions/login.actions';
import { UnsafeAction } from '@nuso/common/util-state';
import { Customer } from '@nuso/orgs/types';

export class State {
  id?: string;
  username?: string;
  name?: string;
  roles: Array<string>;
  permissions: string[];
  redirectUrl: string;
  pending: boolean;
  isLoggedIn: boolean;
  impersonatorId: number;
  current_customer_id?: number;
  currentCustomer?: Customer;
  currentCustomerRedirectUrl?: string | null;
}

// Session Info PAYLOAD........ {
//   "id":1010,
//   "name":"test",
//   "email":"t@test.com",
//   "roles":[],
//   "status":"active",
//   "created_at":"2017-08-26T16:02:22.953Z",
//   "updated_at":"2017-08-26T16:02:22.953Z",
//   "current_customer_id":1000,
//   "currentCustomer":{
//     "id":1000,
//     "name":"Lake Company",
//     "account_number":"10000063301",
//     "status":"approved",
//     "created_at":"2017-05-15T15:56:54.150Z",
//     "updated_at":"2017-08-25T13:56:11.970Z",
//     "main_service_key":"cloud-uc",
//     "customer_id":1000,
//     "user_id":1010}}

export class SessionInfo extends State {
  isSuperuser: boolean;
  isAdmin: boolean;
  isPartner: boolean;
  isNusoAdmin: boolean;
}

const loggedOutState = {
  id: undefined,
  username: undefined,
  name: undefined,
  pending: false,
  isLoggedIn: false,
  roles: [],
  permissions: [],
  redirectUrl: '',
  current_customer_id: undefined,
  currentCustomer: null,
  currentCustomerRedirectUrl: null,
  impersonatorId: null
};

export const initialState = {
  id: undefined,
  username: undefined,
  name: undefined,
  pending: false,
  isLoggedIn: false,
  roles: [],
  permissions: [],
  redirectUrl: '',
  current_customer_id: undefined,
  currentCustomer: null,
  currentCustomerRedirectUrl: null,
  impersonatorId: null
};


function mapRedirectUrl(url: string): string {
  const redirectToRootUrls = [
    '/login',
    '/sso-failed'
  ];

  return redirectToRootUrls.indexOf(url) !== -1 ? '' : url;
}

export function reducer(state = initialState, action: UnsafeAction): State {
  switch (action.type) {
    case '@ngrx/store/init':
      return Object.assign({}, state, {
        id: window.location.pathname === '/validate-token' ? null : localStorage.getItem('loginToken'),
        redirectUrl: state.redirectUrl
          ? state.redirectUrl
          : mapRedirectUrl(window.location.pathname + window.location.search)
      });
    case login.ActionTypes.ATTEMPT_LOGIN:
      return {
        ...loggedOutState,
        pending: true,
        redirectUrl: state.redirectUrl,
        username: action.payload.username,
      };
    case login.ActionTypes.IMPERSONATE_USER:
      return {
        ...loggedOutState,
        pending: true,
        redirectUrl: '/',
        username: action.payload.username,
      };
    case login.ActionTypes.STOP_IMPERSONATING_USER:
      return {
        ...loggedOutState,
        pending: true,
        redirectUrl: '/',
      };
    case login.ActionTypes.ATTEMPT_LOGIN_WITH_RESET_TOKEN:
      return {
        ...loggedOutState,
        pending: true,
        redirectUrl: '/reset-password',
      }
    case login.ActionTypes.ATTEMPT_LOGIN_WITH_NEC_TOKEN:
      return {
        ...loggedOutState,
        pending: true,
        redirectUrl: action.payload.redirectUrl
      }
    case login.ActionTypes.REFRESH_TOKEN:
      return action.payload
        ? {...loggedOutState, id: action.payload, pending: true }
        : loggedOutState;
    case login.ActionTypes.RECEIVE_SESSION_INFO: {
      const info = action.payload || {};
      return {
        ...state,
        ...info,
        permissions: info.allPermissions || [],
        current_customer_id: info.current_customer_id || (info.currentCustomer && info.currentCustomer.id),
        pending: false,
        isLoggedIn: !!info.id,
        username: info.email
      };
    }
    case login.ActionTypes.FAIL_LOGIN:
      return {
        ...state,
        // ...action.payload,
        ...loggedOutState,
        pending: false,
        isLoggedIn: false
      };
    case login.ActionTypes.FAIL_NEC_LOGIN:
      return {
        ...state,
        // ...action.payload,
        ...loggedOutState,
        pending: false,
        isLoggedIn: false,
        redirectUrl: state.redirectUrl
        // redirectUrl: '/'
    };
    case login.ActionTypes.LOGOUT:
      return initialState;
    case login.ActionTypes.SET_REDIRECT_URL:
      return { ...state, redirectUrl: mapRedirectUrl(action.payload) };
    case login.ActionTypes.RESET_REDIRECT_URL:
      return { ...state, redirectUrl: '/' };
    case login.ActionTypes.SET_CURRENT_CUSTOMER_REDIRECT_URL:
      return { ...state, currentCustomerRedirectUrl: action.payload };
    case login.ActionTypes.SET_CURRENT_CUSTOMER:
      return { ...state };
    case login.ActionTypes.UPDATE_CURRENT_CUSTOMER:
    case login.ActionTypes.SET_RETURNED_CURRENT_CUSTOMER:
      return {
        ...state,
        current_customer_id: action.payload.id,
        currentCustomer: action.payload
      };
    case login.ActionTypes.SET_FAILED_CURRENT_CUSTOMER:
      return {
        ...state,
      };

    default:
      return state;
  }
}

export const getIsLoggedIn = (state: State) => state.isLoggedIn;
export const getRoles = (state: State) => state.roles;
export const getPermissions = (state: State) => state.permissions;
export const getRedirectUrl = (state: State) => state.redirectUrl;
export const getId = (state: State) => state.id;
export const getUsername = (state: State) => state.username;
export const getLoginPending = (state: State) => state.pending;
export const getSessionInfo = (state: State) => Object.assign({}, state, {
  isSuperuser: state.roles && includes(state.roles, 'superuser'),
  isAdmin: state.roles && includes(state.roles, 'admin') || includes(state.roles, 'superuser'),
  isPartner: state.roles && includes(state.roles, 'partner'),
  isCustomer: state.roles && includes(state.roles, 'customer'),
  isNusoAdmin: state.roles && (includes(state.roles, 'admin') || includes(state.roles, 'superuser')) && state.username.indexOf('necam') === -1
});
export const getCurrentCustomerId = (state: State) => state.current_customer_id;
export const getCurrentCustomer = (state: State) => state.currentCustomer;
export const getCurrentCustomerRedirectUrl = (state: State) => state.currentCustomerRedirectUrl;
export const getCurrentCustomerMainServiceKey = (state: State) =>
  !state.currentCustomer || !state.currentCustomer.mainServiceKey ? null : state.currentCustomer.mainServiceKey;
export const getCurrentCustomerIsSupportConfigured = (state: State) => state.currentCustomer && !!state.currentCustomer.teamworkCustomerId;
