import { Injectable } from '@angular/core';
import { NetworkStatus, NetworkStatusState, NETWORK_STATUS_QUERY } from './network-status.model';
import { GraphStateService, GraphStateStore } from '../graph-state.service';


export function createInitialNetworkStatusState(): NetworkStatusState {
  return {
    networkStatus: {
      isConnected: true,
      isAuthenticationError: false
    }
  };
}


@Injectable({
  providedIn: 'root'
})
export class NetworkStatusUpdateService {
  store: GraphStateStore<NetworkStatus>;
  
  constructor(private graphStateService: GraphStateService) {
    // note, state should be initialized when cache initialized (likely app.module)
    this.store = this.graphStateService.getStateStore<NetworkStatus>(NETWORK_STATUS_QUERY, 'networkStatus')
  }

  updateConnected(isConnected: boolean) {
    return this.store.updateState({
      ...this.store.currentState(),
      isConnected
    })
  }

  updateAuthenticationError(isAuthenticationError: boolean) {
    return this.store.updateState({
      ...this.store.currentState(),
      isAuthenticationError
    })
  }

}