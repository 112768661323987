import { Injectable } from '@angular/core';
import { Customer } from '@nuso/orgs/types';
import { gql } from 'apollo-angular';
import { GraphStateStore, GraphStateService } from '@nuso/common/data-access-api';
import { Subject } from 'rxjs';

export class LoginStatus {
  pending: boolean;
  isLoggedIn: boolean;
  redirectUrl: string;
  loginSession: LoginSessionInfo;
}

export class LoginSessionInfo {
  id: string;
  username: string;
  name: string;
  roles: string[];
  permissions: string[];
  impersonatorId: number;
  current_customer_id: number;
  currentCustomer: Customer;
}

const defaultState = {
  pending: false,
  isLoggedIn: false,
  redirectUrl: null,
  loginSession: null
}

export interface LoginStatusState {
  loginStatus: LoginStatus
}

export const LOGIN_STATUS_QUERY = gql`
  query loginStatus {
    loginStatus @client
  }
`;

export function createInitialLoginState() {
  return {
    loginStatus: {
      ...defaultState,
      pending: true,
      redirectUrl: mapRedirectUrl(window.location.pathname + window.location.search)
    }
  }
}

function mapRedirectUrl(url: string): string {
  const redirectToRootUrls = [
    '/login',
    '/sso-failed',
    '/logout'
  ];

  return redirectToRootUrls.indexOf(url) !== -1 ? '' : url;
}
@Injectable({
  providedIn: 'root'
})
export class LoginStateService {
  onAttemptLogin = new Subject<LoginStatus>();
  onLogin = new Subject<LoginStatus>();
  onLogout = new Subject<LoginStatus>();

  store: GraphStateStore<LoginStatus>;

  constructor(private graphStateService: GraphStateService) {
    // NOTE: createInitialLoginState() should be called during app initialization
    this.store = this.graphStateService.getStateStore<LoginStatus>(LOGIN_STATUS_QUERY, 'loginStatus');
  }

  currentState() {
    return this.store.currentState();
  }

  setRedirectUrl(redirectUrl: string) {
    return this.store.updateState({
      ...this.store.currentState(),
      redirectUrl
    })
  }

  resetRedirectUrl() {
    return this.setRedirectUrl('/');
  }

  attemptLogin() {
    // NOTE: in the old state, we passed in username password. Not sure we actually care or not
    // if we care, may need to move username up into pendingUsername or something at top level
    const state = this.store.currentState();
    this.onAttemptLogin.next(state)
    return this.startLoginAttempt(state.redirectUrl);
  }

  impersonateUser() {
    return this.startLoginAttempt('/')
  }

  stopImpersonateUser() {
    return this.startLoginAttempt('/');
  }

  attemptLoginWithResetToken() {
    return this.attemptLogin();
  }

  attemptLoginWithNecToken(redirectUrl: string) {
    return this.startLoginAttempt(redirectUrl);
  }

  failLogin() {
    const { redirectUrl } = this.store.currentState();

    return this.store.updateState({
      ...defaultState,
      redirectUrl
    })
  }

  refreshToken(token: string) {
    // NOTE: old store added id: token if token specified
    const newState = token ? {...defaultState, pending: true } : defaultState;
    return this.store.updateState(newState);
  }

  receiveSessionInfo(loginSession: LoginSessionInfo) {
    const state = this.store.currentState();

    // TODO: permissions: action.payload.allPermissions || [],
    // current_customer_id: action.payload.current_customer_id || (action.payload.currentCustomer && action.payload.currentCustomer.id),
    const newState = this.store.updateState({
      ...state,
      pending: false,
      isLoggedIn: (loginSession && !!loginSession.id) ,
      loginSession
    })

    if (newState.isLoggedIn) {
      this.onLogin.next(newState);
    }

    return newState;
  }

  logout() {
    const newState = this.store.updateState(defaultState);
    this.onLogout.next(newState);
    return newState;
  }

  private startLoginAttempt(redirectUrl: string) {
    return this.store.updateState({
      ...defaultState,
      pending: true,
      redirectUrl: redirectUrl
    })
  }


}