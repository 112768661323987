import {gql} from 'apollo-angular';
import { Injectable, Inject } from '@angular/core';

import { GraphApiService } from '@nuso/common/data-access-api';
import { Observable } from 'rxjs';

import {
  AppEnvironment,
  appEnvironmentToken,
  NecEnvironment,
  necEnvironmentToken
} from '@nuso/portal/environments';
import { map } from 'rxjs/operators';
import { PartnerSite } from '@nuso/orgs/types';
import { Branding, getBrandFromHost } from '@nuso/portal/util-branding';


const siteForHostQuery = gql`
  query siteForHost($host: String!) {
    siteForHost(host: $host) {
      id
      key
      title
      appName
      privateLabel
      enabled
      logoUrl
      printableLogoUrl
      logoLinkUrl
      toolbarColor
    }
  }
`;


@Injectable()
export class BrandingService {

  private brand: string;

  constructor(
    @Inject(appEnvironmentToken) private appEnv: AppEnvironment,
    @Inject(necEnvironmentToken) private necEnv: NecEnvironment,
    private graphApi: GraphApiService
  ) {
    this.brand = this.getPrivateLabelBrand();
  }

  getBranding(): Observable<Branding> {
    const host = window.location.hostname;

    return this.graphApi.watchQuery<PartnerSite>({
      query: siteForHostQuery,
      variables: {
        host
      },
      fetchPolicy: 'cache-first'
    }).pipe(
      map(site => ({
        ...this.getPrivateLabelBranding(),
        ...(site || {})
      }))
    )
  }

  getPrivateLabelBrand() {
    return getBrandFromHost(window.location.hostname, this.appEnv.envName);
  }

  getPrivateLabelBranding(): Branding {
    const portString = window.location.port !== '' ? `:${window.location.port}` : '';
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${portString}`;
    const { necSsoEnabled, necSsoLoginUrl } = this.necEnv;
    const { ssoEnabled, ssoLoginUrl, appName } = this.appEnv;
    const ssoLoginLink = ssoLoginUrl && `${ssoLoginUrl}?redirectUrl=${encodeURIComponent(baseUrl)}/validate-token`;

    const defaultSupportContacts = {
      quotingSupportPhone: '(844) 438-6876 and select option 3',
      quotingSupportEmail: 'contact@nuso.cloud',
      portingSupportPhone: '(844) 438-6876 and select option 2',
      portingSupportEmail: 'sf.porting@nuso.cloud',
      technicalSupportPhone: '(844) 438-6876 and select option 1',
      technicalSupportEmail: 'support@nuso.cloud',
    }

    switch (this.brand) {
      case 'northwell':
      case 'nec':
        return {
          baseUrl,
          brand: 'nec',
          appName,
          title: 'Univerge Blue - Management Portal',
          toolbarColor: 'background',
          logoUrl: 'https://cdn.univergeblue.com/img/logo/logo.gif',
          logoLinkUrl: necSsoLoginUrl,                                  // 'https://portal.univergeblue.com' || ubportal.necproj.com
          logoutUrl: `${necSsoLoginUrl}/Identity/Account/Logout`,
          profileLink: necSsoEnabled && `${necSsoLoginUrl}/myprofile`,
          ssoLoginLink: necSsoEnabled && `${necSsoLoginUrl}/sso?target=${baseUrl}/validate-token`,
          ssoEnabled: necSsoEnabled,
          partnerHomepage: true,
          partnerPortingDashboard: this.brand === 'nec',
          manualPortOrdersSupported: this.brand === 'nec',
          partnerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07200207/Whitelabel-Management-Portal-Support.pdf',
          customerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07200159/Customer-Management-Portal-Support.pdf',
          customerE911GuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/04/28204503/NEC-SIP-E911-Compliance.pdf',
          quotingSupportPhone: '(866) 778-7764 and select option 3',
          quotingSupportEmail: 'necorders@nuso.cloud',
          portingSupportPhone: '(866) 778-7764 and select option 5',
          portingSupportEmail: 'sf.porting@nuso.cloud',
          technicalSupportPhone: '(866) 778-7764 and select option 2',
          technicalSupportEmail: 'necsupport@nuso.cloud',
        }
      case 'nuso':
        return {
          baseUrl,
          brand: 'nuso',
          appName,
          title: 'nuso - management portal',
          toolbarColor: 'primary',
          logoUrl: '/assets/images/nuso-white-60.png',
          logoLinkUrl: '/',
          partnerHomepage: true,
          partnerPortingDashboard: true,
          manualPortOrdersSupported: false, // will turn on for our admins
          ssoEnabled,
          ssoLoginLink,
          partnerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07181749/NUSO-Management-Portal-Support.pdf',
          customerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07200159/Customer-Management-Portal-Support.pdf',
          customerE911GuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/04/28191133/NUSO-SIP-E911-Compliance.pdf',
          ...defaultSupportContacts
        }
      default:
        return {
          baseUrl,
          brand: 'marketspace',
          appName,
          title: 'Marketspace Portal',
          toolbarColor: 'primary',
          logoLinkUrl: '/',
          partnerHomepage: true,
          partnerPortingDashboard: true,
          manualPortOrdersSupported: false,
          ssoEnabled,
          ssoLoginLink,
          partnerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07200207/Whitelabel-Management-Portal-Support.pdf',
          customerSupportGuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/01/07200159/Customer-Management-Portal-Support.pdf',
          customerE911GuideUrl: 'https://cdn.allbound.com/nuso-ab/2021/04/28191133/NUSO-SIP-E911-Compliance.pdf',
          ...defaultSupportContacts
        }
    }
  }
}
