import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { RouterModule, Route } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  RavenErrorLoggingService,
  createRavenErrorHandler,
} from '@nuso/common/util-errors';

import { createReducers } from '@nuso/portal/state';
import * as Sentry from '@sentry/angular-ivy';

import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import {
  createInitialNetworkStatusState,
  NetworkStatusUpdateService,
} from '@nuso/common/data-access-api';
import { createInitialLoginState } from '@nuso/auth/data-access';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { XTabIdInterceptor } from '@nuso/common/data-access-api';

import {
  AppEnvironment,
  NecEnvironment,
  PortalAppFeatures,
  isProductionToken,
  appEnvironmentToken,
  necEnvironmentToken,
  portalAppFeaturesToken,
} from '@nuso/portal/environments';
import {
  HttpClient,
  HttpClientModule,
  HttpErrorResponse,
} from '@angular/common/http';
import { createApollo } from '@nuso/common/data-access-api';
import { createRavenErrorLoggingService } from '@nuso/common/util-errors';

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry,
    environment: environment.envName,
    release: window['MARKETSPACE_VERSION'],
  });
}

const appEnvironment: AppEnvironment = {
  envName: environment.envName,
  appName: environment.appName,
  production: environment.production,
  sentry: environment.sentry,
  showDebug: environment.showDebug,
  ssoEnabled: environment.ssoEnabled,
  ssoLoginUrl: environment.ssoLoginUrl,
  userPortalUrl: environment.userPortalUrl,
  statusPageIoEmbedUrl: environment.statusPageIoEmbedUrl,
};

const necEnvironment: NecEnvironment = {
  necSsoEnabled: environment.necSsoEnabled,
  necSsoLoginUrl: environment.necSsoLoginUrl,
};

const portalAppFeatures: PortalAppFeatures = environment;

const mainRoute: Route = {
  path: '',
  loadChildren: () =>
    import('@nuso/portal/feature-shell').then(
      (m) => m.PortalFeatureShellModule
    ),

  // You could put authentication guards here to run checks before lazy-loading main
  canLoad: [],
  canActivate: [],
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function createInitialAppState() {
  return {
    ROOT_QUERY: {
      ...createInitialNetworkStatusState(),
      ...createInitialLoginState(),
    },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([mainRoute], {
      initialNavigation: 'enabledBlocking',
      relativeLinkResolution: 'legacy',
    }),
    ApolloModule,
    MatSnackBarModule,
    // Below was added when we tried creating an empty store app
    // commenting out for now, and leaving the forRoot in the
    // feature-monolith... once we can break state into own lib, can move
    // back here (or not.)
    // might be best to move things into forFeature pieces
    StoreModule.forRoot(createReducers(), {
      initialState: {
        loginStatus: {
          id: undefined,
          username: undefined,
          pending: true, // NEED THIS FOR GUARD
          isLoggedIn: false,
          roles: [],
          permissions: [],
          redirectUrl: null, // this will need to get set in the reducer to window.location for AOT
          impersonatorId: null,
        },
      },
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    HttpClientModule,
    // EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],
    // StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    { provide: isProductionToken, useValue: environment.production },
    { provide: appEnvironmentToken, useValue: appEnvironment },
    { provide: necEnvironmentToken, useValue: necEnvironment },
    { provide: portalAppFeaturesToken, useValue: portalAppFeatures },
    {
      provide: InMemoryCache,
      useValue: new InMemoryCache({
        typePolicies: {
          IPFaxCoverPageFields: {
            keyFields: ['id', 'type'],
          },
          OSSCallFwdRule: {
            keyFields: ['targetId', 'cfType', 'dialedDid'],
          },
          TnDetail: {
            keyFields: ['tn'],
          },
          CampaignDid: {
            keyFields: ['did']
          },
          Customer: {
            fields: {
              pbxInfo: {
                merge: true
              }
            }
          },
          PbxVerificationInfo: {
            fields: {
              pbxDetails: {
                merge: true
              },
              ossSipDetails: {
                merge: true
              }
            }
          }
        },
      }).restore(createInitialAppState()),
    },
    {
      provide: ErrorHandler,
      useValue: createRavenErrorHandler(
        environment.production,
        environment.envName === 'local'
      ),
    },
    {
      provide: RavenErrorLoggingService,
      useValue: createRavenErrorLoggingService(environment.production),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
        InMemoryCache,
        NetworkStatusUpdateService,
        isProductionToken,
      ],
    },
    { provide: HTTP_INTERCEPTORS, useClass: XTabIdInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private apollo: Apollo, private cache: InMemoryCache) {
    console.log(environment.envName, window['MARKETSPACE_VERSION']);
    this.apollo.client.onClearStore(() => {
      console.log('RESETTING STORE');
      this.cache.restore(createInitialAppState());
      return Promise.resolve();
    });
  }
}
