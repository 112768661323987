
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from '@nuso/common/util-errors';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private httpOptions: { headers: HttpHeaders };

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  get(path: string): Observable<any> {
    return this.http.get(path)
      .pipe(
        catchError(e => this.handleError(e))
      )
  }

  post(path: string, data: object): Observable<any> {
    return this.http.post(path, data, this.httpOptions)
      .pipe(
        catchError(e => this.handleError(e))
      )
  }

  put(path: string, data: object): Observable<any> {
    return this.http.put(path, data, this.httpOptions)
      .pipe(
        catchError(e => this.handleError(e))
      )
  }

  delete(path: string): Observable<any> {
    return this.http.delete(path, this.httpOptions)
      .pipe(
        catchError(e => this.handleError(e))
      )
  }


  ping(): Observable<boolean> {
    return this.http.get('/api/ping') // Doesn't really matter what we hit
      .pipe(
        map(x => true),
        catchError(err => of(false))
      )
  }


  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      // note, if start returning errors in json format, need error.json() and grab body.error or whatever
      // will need to put .json() in a try/catch, as sometimes data may be text
      const body = error.text() || '';
      errMsg = `${error.statusText || 'API Error'}(${error.status}) ${body}`;
    } else if (error instanceof HttpErrorResponse) {
      if (error.status === 422) {
        errMsg = this.translateService.instant('login.invalid-username');
      } else {
        errMsg = `${error.statusText || 'API Error'}(${error.status})`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error('API error: ', errMsg);
    this.notificationService.addError(errMsg);
    return observableThrowError(error);
  }

}

