import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, Resolvers } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { HttpErrorResponse } from '@angular/common/http';
import { createGraphqlErrorLink } from './graphql-error-link';
import { NetworkStatusUpdateService } from './network-status/network-status-update.service';
import { compact } from 'lodash';

export interface CreateApolloExtraOptions {
  resolvers?: Resolvers
}

export function createApollo(
  httpLink: HttpLink, 
  cache: InMemoryCache, 
  networkStatus: NetworkStatusUpdateService, 
  isProduction: boolean,
  options?: CreateApolloExtraOptions
) {
  const { resolvers = {} } = (options || {});

  return {
    link: ApolloLink.from([
      new RetryLink({
        delay: (count, operation, error) => {
          // I've found the default jitter based method to potentially retry too quickly
          // So instead of random between 0ms and calculated delay
          // randomize between previousMaxDelay and the calculated delay for this time
          const baseDelay = 300;
          const minDelay = count === 0 ? baseDelay : baseDelay * 2 ** (count - 1);
          let delay = baseDelay * 2 ** count;
          delay = Math.max(Math.random() * delay, minDelay);
          console.log('delaying', delay);
          return delay;
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => {
            // leaving comment, in case we need to not retry based on certain operations/graph calls
            // console.log('REATTEMPT APOLLO...........', { error, _operation });
            // if ( _operation &&  _.includes(['necAddExtensionToRingGroup', 'necRemoveExtensionFromRingGroup'], 
            //                         _.get(_operation, 'operationName')) ) {
            //   console.log('do not reattempt... operation is ', _.get(_operation, 'operationName'));
            //   return false;
            // }
            // console.log('yes... reattempt... operation is ', _.get(_operation, 'operationName'));
            return error instanceof HttpErrorResponse && ([502, 503, 504].indexOf(error.status) !== -1);
          }
        }
      }),
      createGraphqlErrorLink(networkStatus, isProduction),
      // graphqlErrorLink,
      httpLink.create({
        uri: 'graphql',
        // opts: {
        //   credentials: 'same-origin'
        // },
      })
    ]),
    cache,
    resolvers: {
      User: {
        nameAndOrganization: (user) => compact([user.name, user.organization]).join(' - ')
      },
      ...resolvers
    },
    connectToDevTools: !isProduction,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'none'
      },
      mutate: {
        errorPolicy: 'none'
      }
    }
  }
}