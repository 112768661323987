
import {take, tap, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import * as page from '../actions/page.actions';
import * as fromRoot from '../reducers/index';
import { Store } from '@ngrx/store';

@Injectable()
export class PageEffects {

  
  changePage$ = createEffect(() => this.actions$.pipe(
    ofType(page.ActionTypes.LOAD_PAGE),
    tap((action: page.LoadPageAction) => this.router.navigateByUrl(action.payload))), { dispatch: false });

  
  previousPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(page.ActionTypes.LOAD_PREVIOUS_PAGE),
      switchMap(() => this.store.select(fromRoot.getPagePrevious).pipe(take(1))),
      tap((previousPath) => this.router.navigateByUrl(previousPath))
    ), { dispatch: false });

    constructor(
      private actions$: Actions,
      private store: Store<fromRoot.State>,
      private router: Router
    ) {
    }
}
