
import {of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { get } from 'lodash';
import { catchError } from 'rxjs/operators';
import { RavenErrorLoggingService } from './raven-logging.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar, private errorService: RavenErrorLoggingService) {
  }

  addError(message: string) {
    this.snackBarWrapper(message, { panelClass: ['error'] });
  }

  clearErrors() {
    this.snackBar.dismiss();
  }

  notify(message: string) {
    this.snackBarWrapper(message, { duration: 3000 });
  }

  snackBarWrapper(message: string, config?: MatSnackBarConfig) {
    this.snackBar.open(message, 'x', config);
  }

  // Note, we are now relying on a global graphqlErrorLink formatting the deeper error.errors.0.message into the main error.message
  buildGraphqlErrorMsg(error: any, msgPrefix?: string) {
    const fullServerMessage = get(error, 'message', error);
    const message = fullServerMessage.replace('GraphQL error:', '').trim();
    return msgPrefix
      ? `${msgPrefix} - ${message}`
      : message;
  }

  addGraphqlError(error: any, msgPrefix?: string) {
    this.errorService.logError(error);
    const errorMsg = this.buildGraphqlErrorMsg(error, msgPrefix);
    this.addError(errorMsg);
    console.log({errorMsg});
    return errorMsg;
  }

  catchGraphError(msgPrefix: string, defaultValue: any) {
    return catchError(err => {

      this.addGraphqlError(err, msgPrefix);
      return observableOf(defaultValue);
    })
  }
}
