import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './effects/login.effects';
import { PageEffects } from './effects/page.effects';
import { MyCustomersEffects } from './effects/my-customers.effects';


@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      LoginEffects,
      PageEffects,
      MyCustomersEffects,
    ]),
  ],
})
export class PortalStateModule {}
