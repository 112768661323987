
import {gql} from 'apollo-angular';
import {map, switchMap, catchError} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';


import * as fromMyCustomers from '../actions/my-customers.actions';

import { NotificationService } from '@nuso/common/util-errors';
import { GraphApiService } from '@nuso/common/data-access-api';
import { Customer } from '@nuso/orgs/types';

const MyCustomersGQL = gql`
  query myCustomers {
    myCustomers {
      id
      name
      displayName
      accountNumber
      billingAccountNumber
      reportingAccountNumber
      regionCode
      timezone
      status
      mainServiceKey
      teamworkCustomerId
    }
  }
`;



@Injectable()
export class MyCustomersEffects {
  
  loadCustomers$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(fromMyCustomers.ActionTypes.LOAD_CUSTOMERS),
    switchMap((action: fromMyCustomers.LoadCustomersAction) => {
        return this.graphApi.watchQuery<Customer[]>({
          query: MyCustomersGQL,
          fetchPolicy: 'network-only',
        })
        .pipe(
          map(myCustomers => {
            return new fromMyCustomers
            .LoadReturnedAction(
              _.cloneDeep(myCustomers));
          }),
          catchError(e => {
            return of(new fromMyCustomers.LoadFailedAction(
              this.notificationService.addGraphqlError(e, 'Failed to Load Customers')
            ));
          })
        )
      }
    )));


  constructor(
    private action$: Actions,
    private graphApi: GraphApiService,
    protected notificationService: NotificationService
  ) {
  }
}
