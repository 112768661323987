import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { HttpErrorResponse } from '@angular/common/http';

function handleOldVersion(err) {
  const stack = err.originalStack || err.stack;
  if (stack && stack.indexOf('Error: Loading chunk') >= 0) {
    // Check if is the first time the error happend

    // our goal here is to not have infinite reloads, so we are going to do a combination
    // of things to write to the lastChunkError in order to
    // 1. make sure we don't infinite reload if chung really not there
    // 2. make sure we actually do reload, in case somehow the
    // --- either window.location.replace doesn't force a page load
    // --- somehow the stack trace is the same for two different urls
    const navigateTo = localStorage.getItem('navigateTo');
    const lastChunkError = localStorage.getItem('lastChunkError');
    const portalVersion = window['MARKETSPACE_VERSION'];
    const currentPath = window.location.pathname;

    const newChunkError = `
     v: ${portalVersion},
     on: ${currentPath},
     to: ${navigateTo},
     stack: ${stack}
    `;

    if (lastChunkError !== newChunkError) {
      // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
      localStorage.setItem('lastChunkError', newChunkError);
      if (navigateTo && navigateTo !== currentPath ) {
        window.location.replace(navigateTo);
      } else {
        // I see that windows.location.reload(true) is deprecated (12/3/2019). It appears that
        // passing in the forceReload boolean arg is not part of the spec
        // If we need to change this, the idea was to pass that in to force a reload to hopefully
        // avoid reading from the browser cache. This may not be necessary... we might be fine
        // just calling reload without the force.
        //
        // Given that the index.html has the following header:
        // <meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0">
        // we should be good with a regular reload
        window.location.reload();
      }
      return true;
    } else {
      // The chunk really does not exists after reload
      console.error('We did not find the chunk.');
      return false;
    }
  }

  return false;
}

export class RavenErrorHandler implements ErrorHandler {
  constructor(private isProduction: boolean, private logErrors: boolean) {
  }

  handleError(err: any): void {
    const isOldVersion = handleOldVersion(err);
    if ( !isOldVersion ) {
      const extractedError = extractError(err) || err || 'Handled unknown error';
      // eslint-disable-next-line no-console
      if (this.logErrors) {
        console.error('sentry', extractedError);
      }
            
      if (this.isProduction) {
        Sentry.captureException(extractedError, {
          tags: { uncaught: 'uncaught' }
        });
      } else {
        // Commenting out. This is how we used to do it, but this appears to perpetually
        // fire the exception
        // throw err;  // re-throw, so it will bubble up. Useful for integration tests
      }
    }
  }
}

export function createRavenErrorHandler(isProduction: boolean, logErrors: boolean) {
  return new RavenErrorHandler(isProduction, logErrors);
}

////////////////////
// Taken from @sentry/angular to help build our own error handler

/**
* Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
*/
function extractError(errorCandidate) {
  let error = errorCandidate;
  // Try to unwrap zone.js error.
  // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
  if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
  }
  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
      return error;
  }
  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
          return error.error;
      }
      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
          return error.error.message;
      }
      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
          return "Server returned code " + error.status + " with body \"" + error.error + "\"";
      }
      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
  }
  // Nothing was extracted, fallback to default error message.
  return null;
};
