import { defaults } from './env-defaults';
import { PortalAppEnvironment } from '@nuso/portal/environments';

export const environment: PortalAppEnvironment = {
  ...defaults,
  envName: 'prod',
  production: true,
  sentry: 'https://624a4ac15843458bb357c26d964f4453@sentry.io/1191703',
  ssoEnabled: true,
  ssoLoginUrl: 'https://login.nuso.io',
  userPortalUrl: 'https://my.nuso.io',
  necSsoEnabled: true,
  necSsoLoginUrl: 'https://portal.univergeblue.com',
  customerTestingEnabled: false,
  salesforceSupportEnabled: true,
  archivedSupportEnabled: false,
  isTestDeleteMeta: false, // Can proper delete pbx/sip bindings on prod
  statusPageIoEmbedUrl: 'https://cy964sjcc8dg.statuspage.io/embed/frame', // status.nuso.cloud
};
