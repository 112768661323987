import { Injectable } from '@angular/core';
import { captureException, setContext } from '@sentry/browser';

export function createRavenErrorLoggingService(isProduction: boolean) {
  return new RavenErrorLoggingService(isProduction);
}

export class RavenErrorLoggingService {
  constructor(private isProduction: boolean) {
  }

  logError(err: any) {
    if ( this.isProduction ) {
      return captureException(err);
    } else {
      return console.log(err);
    }
  }

  setUserContext(user?: any) {
    if ( !this.isProduction ) {
      console.log('setUserContext', user);
    }
    setContext('user', user);
  }
  
  resetUserContext() {
    this.setUserContext();
  }
}