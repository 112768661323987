
import {gql} from 'apollo-angular';
import {
  switchMap,
  startWith,
  observeOn,
  ignoreElements,
  map,
  concatMap,
  catchError,
  filter,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';


import { Observable, of as observableOf} from 'rxjs';
import { asyncScheduler } from 'rxjs';



import { RavenErrorLoggingService } from '@nuso/common/util-errors';

import * as login from '../actions/login.actions';
import * as fromRoot from '../reducers/index';
import { LoginService } from '@nuso/auth/data-access';
import { LoadCustomersAction, ResetCustomersAction } from '../actions/my-customers.actions';
import { NotificationService } from '@nuso/common/util-errors';
import { LogoutAction, SessionExpiredAction } from '../actions/login.actions';
import { BrandingService } from '@nuso/portal/data-access-branding';
import { GraphApiService, NETWORK_STATUS_QUERY, NetworkStatus, NetworkStatusUpdateService } from '@nuso/common/data-access-api';
import { State as LoginState } from '../reducers/login';


export const SetCurrentCustomerGQL = gql`
  mutation setCurrentCustomer($custId: Int) {
    setCurrentCustomer(id: $custId) {
      id
      name
      accountNumber
      mainServiceKey
      timezone
      status
      teamworkCustomerId
    }
  }
`;

@Injectable()
export class LoginEffects {


  login$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.ATTEMPT_LOGIN),
      map((action: login.AttemptLoginAction) => action.payload),
      tap(() => this.notificationService.clearErrors()),
      switchMap(payload => this.loginService.login(payload.username, payload.password)
        .pipe(
          map(res => new login.ReceiveSessionInfoAction(res) ),
          catchError((e) => observableOf(new login.FailLoginAction(e)))
        )
      ),
    ));


  impersonateUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.IMPERSONATE_USER),
      map((action: login.ImpersonateUserAction) => action.payload),
      concatMap((userId: number) => this.loginService.impersonateUser(userId)),
      tap(() => window.location.href = '/'), // Just make for certain we get all of the state reset that we need to
      // This is what we used to do, but above tap will short circuit everything. This keeps the return value gods happy
      map(res => new login.ReceiveSessionInfoAction(res)),
      catchError((e) => observableOf(new login.FailLoginAction(e)))
    ));


  stopImpersonatingUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.STOP_IMPERSONATING_USER),
      concatMap(() => this.loginService.stopImpersonateUser()),
      tap(() => window.location.href = '/'), // see notes in impersonate
      map(res => new login.ReceiveSessionInfoAction(res)),
      catchError((e) => observableOf(new login.FailLoginAction(e)))
    ));


  loginWithResetToken$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.ATTEMPT_LOGIN_WITH_RESET_TOKEN),
      map((action: login.AttemptLoginWithResetTokenAction) => action.payload),
      concatMap((token: string) => this.loginService.loginWithResetToken(token)),
      map(res => new login.ReceiveSessionInfoAction(res)),
      catchError((e) => observableOf(new login.FailLoginAction(e)))
    ));


    loginWithNecToken$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(login.ActionTypes.ATTEMPT_LOGIN_WITH_NEC_TOKEN),
        map((action: login.AttemptLoginWithNecTokenAction) => action.payload.token),
        concatMap((token: string) => this.brandingService.getPrivateLabelBranding().brand === 'nec' ?
          this.loginService.loginWithNecToken(token) :
          this.loginService.loginWithAuthToken(token, 'Management Portal')),
        tap(res => {
          if (this.brandingService.getPrivateLabelBranding().brand !== 'nec') {
            this.loginService.setSsoLoginFlag();
          }
        }),
        map(res => {
          this.loginService.resetSsoRedirectUrl();
          return new login.ReceiveSessionInfoAction(res);
        }),
        catchError((e) => {
          return observableOf(new login.FailNECLoginAction('Login NEC Token failed' + e))
        })
      ));


  refreshToken$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.REFRESH_TOKEN),
      startWith(new login.RefreshLoginTokenAction(localStorage.getItem('loginToken'))),
      map((action: login.RefreshLoginTokenAction) => action.payload),
      switchMap(token => {
        if (token) {
          return this.loginService.refreshToken(token)
            .pipe(
              map(res => new login.ReceiveSessionInfoAction(res)),
              observeOn(asyncScheduler), // Added this for startup purposes. If no login token, wasn't redirecting.
              catchError((e) => observableOf(new login.FailLoginAction(e)))
            )
        } else {
          return observableOf(new login.ReceiveSessionInfoAction({}));
        }
      })
    ));


  redirectOnLogin$ = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.RECEIVE_SESSION_INFO),
      concatMap(() => this.store.select(fromRoot.getLoginState).pipe(take(1))),
      filter(loginState => loginState.isLoggedIn),
      tap(loginState => this.errorLoggingService.setUserContext(loginState)),
      tap(loginState => this.router.navigateByUrl(loginState.redirectUrl)),
      map(() => new LoadCustomersAction())
    ))


  sessionExpired$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.SESSION_EXPIRED),
      map(() => new LogoutAction())
    ))


  loginFailed$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(login.ActionTypes.FAIL_LOGIN),
        tap((x: login.FailLoginAction) => this.notificationService.addError(x.payload))
      ), { dispatch: false })


  failNECLogin$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.FAIL_NEC_LOGIN),
      tap((x: login.FailNECLoginAction) => console.log(x.payload)),
      switchMap(() => this.loginService.logout()),
      tap(x => this.notificationService.addError(
      `Your token has expired or you are not authorized to use this system.
       Please contact your system administrator for more information.`)),
      concatMap(() => this.store.select(fromRoot.getLoginState).pipe(take(1))),
      // Redirect to a page that can display the error, then give them a button to click
      // to go elsewhere... (re-attempt login or straight logout)
      tap((loginState: LoginState) => {
        const { ssoEnabled } = this.brandingService.getPrivateLabelBranding();
        const url = ssoEnabled ? '/sso-failed' : (loginState.redirectUrl || '/');
        this.router.navigateByUrl(url, { replaceUrl: true });
      }),
      ignoreElements()
    ), { dispatch: false });

  redirectOnLogout$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.LOGOUT),
      switchMap(() => this.loginService.logout()),
      tap(x => this.errorLoggingService.resetUserContext()),
      tap(x => this.store.dispatch(new ResetCustomersAction())),
      tap(x => this.graphApi.apollo.client.clearStore()),
      // Leaving the following here in case we run into the "Reset store" error
      // didn't use this approach, since clearStore may stop returning a promise
      // basically, we were firing off a currentCustomer query right when we cleared the customers
      // state, and cleared the store at the same time
      // tap(x => this.apollo.client.clearStore().then(() => this.store.dispatch(new ResetCustomersAction()))),
      tap(x => this.notificationService.clearErrors()),
      tap(() => {
        const { logoutUrl, ssoLoginLink } = this.brandingService.getPrivateLabelBranding();
        if ( logoutUrl ) {
          window.location.replace(logoutUrl);
        } else if (this.loginService.ssoLoginFlag() && ssoLoginLink) {
          this.loginService.resetSsoLoginFlag();
          window.location.replace(ssoLoginLink);
        } else {
          this.router.navigateByUrl('/', { replaceUrl: true });
        }
      }),
      ignoreElements()
    ), { dispatch: false });


  setCurrentCustomer$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.SET_CURRENT_CUSTOMER),
      switchMap((action: login.SetCurrentCustomerAction) => {
        return this.graphApi.mutate({
          mutation: SetCurrentCustomerGQL,
          variables: {
            custId: action.payload
          }
        })
        .pipe(
          map(results => {
            const data: any = results.data;
            return new login
            .SetReturnedCurrentCustomerAction(
              JSON.parse(JSON.stringify(data.setCurrentCustomer)));
          }
        ),
        catchError(e => observableOf(new login.SetFailedCurrentCustomerAction(e)))
      )
    })
  ));


  // redirectToMainServiceOnCustomerChange$: Observable<any> = this.actions$
  //   .pipe(
  //     ofType(login.ActionTypes.SET_RETURNED_CURRENT_CUSTOMER),
  //     map((action: login.SetReturnedCurrentCustomerAction) => action.payload),
  //     tap(customer => {
  //       this.router.navigateByUrl(`/customer/${customer.id}`);
  //     }),
  //   )
  redirectToMainServiceOnCustomerChange$: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.SET_RETURNED_CURRENT_CUSTOMER),
      map((action: login.SetReturnedCurrentCustomerAction) => action.payload),
      withLatestFrom((this.store.select(fromRoot.geCurrentCustomerLoginRedirectUrl))),
      tap(([customer, currentCustomerRedirectUrl]) => {
        console.log({ customer, currentCustomerRedirectUrl });
        if (currentCustomerRedirectUrl) {
          this.store.dispatch(new login.SetCurrentCustomerRedirectUrlAction(null));
          this.router.navigateByUrl(currentCustomerRedirectUrl);
        } else if (customer.mainServiceKey) {
          this.router.navigateByUrl(`/${customer.mainServiceKey}`)
        } else {
          this.router.navigateByUrl(`/customer/${customer.id}`);
        }
      }),
    ), { dispatch: false })



  setUserContextWithNewCustomer$: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(login.ActionTypes.SET_RETURNED_CURRENT_CUSTOMER),
      concatMap(x => this.store.select(fromRoot.getLoginState).pipe(take(1))),
      tap(loginState => this.errorLoggingService.setUserContext(loginState)),
    ), { dispatch: false })

  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private brandingService: BrandingService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private notificationService: NotificationService,
    private networkService: NetworkStatusUpdateService,
    private graphApi: GraphApiService,
    private errorLoggingService: RavenErrorLoggingService
  ) {
    this.store.select(fromRoot.getLoginToken)
      .subscribe((x) => x ? localStorage.setItem('loginToken', x) : localStorage.removeItem('loginToken'));

    this.graphApi.watchQuery<NetworkStatus>({
      query: NETWORK_STATUS_QUERY
    }).pipe(
      tap((status: NetworkStatus) => {
        console.log('LOGIN EFFECT STATUS', status)
        if (status.isAuthenticationError) {
          console.log('authentication appears to have expired');
          this.networkService.updateAuthenticationError(false);
          this.store.dispatch(new SessionExpiredAction())
        }
      })
    ).subscribe();
  }

}
