import * as page from '../actions/page.actions';

export interface State {
  loading: boolean;
  to: string;
  current: string;
  previous: string;
}

const initialState: State = {
  loading: false,
  to: null,
  current: null,
  previous: null
};

export function reducer(state = initialState, action: page.Actions): State {
  switch (action.type) {
    case page.ActionTypes.LOAD_PAGE:
      return Object.assign({}, state, { to: action.payload });
    case page.ActionTypes.PAGE_LOADING:
      return Object.assign({}, state, { loading: true, to: action.payload});
    case page.ActionTypes.PAGE_LOADED:
      return { loading: false, to: null, current: action.payload, previous: state.current };
    case page.ActionTypes.PAGE_CANCELLED:
    case page.ActionTypes.PAGE_ERRORED:
      return Object.assign({}, state, { loading: false, to: null, current: null });
    default:
      return state;
  }
}

export const getLoading = (state: State) => state.loading;
export const getCurrent = (state: State) => state.current;
export const getPrevious = (state: State) => state.previous;
