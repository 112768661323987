import { type } from '@nuso/common/util-state';
import { Action } from '@ngrx/store';

import { WindowDimensions } from '../types'

// list of actions
export const UiActionTypes = {
  RESIZE_WINDOW: type('[UI] Window Resized'),
  HAS_SIDE_MENU: type('[UI] Component Has a Side Menu'),
  REMOVE_SIDE_MENU: type('[UI] Component Does Not Have a Side Menu'),
  TOGGLE_SIDE_MENU: type('[UI] Toggle Side Menu'),
  CLOSE_SIDE_MENU: type('[UI] Close Side Menu')
}

// action creators: func only job is to return the correct shape of the action
export class ResizeWindowAction implements Action {
  type = UiActionTypes.RESIZE_WINDOW

  constructor(public payload: WindowDimensions) {}
}

export class ToggleSideMenuAction implements Action {
  type = UiActionTypes.TOGGLE_SIDE_MENU

  constructor() {}
}

export class CloseSideMenuAction implements Action {
  type = UiActionTypes.CLOSE_SIDE_MENU

  constructor() {}
}

export class HasSideMenuAction implements Action {
  type = UiActionTypes.HAS_SIDE_MENU

  constructor() {}
}

export class RemoveSideMenuAction implements Action {
  type = UiActionTypes.REMOVE_SIDE_MENU

  constructor() {}
}

export type UiActions
  = ResizeWindowAction
  | HasSideMenuAction
  | RemoveSideMenuAction
  | ToggleSideMenuAction
  | CloseSideMenuAction;
