import { InMemoryCache, DocumentNode } from '@apollo/client/core';
import { Injectable } from '@angular/core';


export class GraphStateStore<T> {
  constructor(private cache: InMemoryCache, private query: DocumentNode, private key: string) {
  }

  currentState(): T {
    return this.cache.readQuery<T>({ query: this.query })[this.key];
  }

  updateState(state: T) {
    this.cache.writeQuery({ query: this.query, data: { [this.key]: state }});
    // console.log('state change', state);
    return state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GraphStateService {
  constructor(private cache: InMemoryCache) {
  }

  getStateStore<T>(query: DocumentNode, key: string) {
    return new GraphStateStore<T>(this.cache, query, key);
  }
}
