import { UnsafeAction } from '@nuso/common/util-state';
import * as ui from '../actions/ui.actions';
import { WindowDimensions } from '../types'

const breakpoint = 768;

export class UiState {
  showMobileMenu: boolean;
  hasSideMenu: boolean;
  sideMenuOpened: boolean;
  windowSize: WindowDimensions
}

export const initialState = {
  showMobileMenu: false,
  hasSideMenu: false,
  sideMenuOpened: true,
  windowSize: {
    height: window.screen.height,
    width: window.screen.width
  }
}

// this.router.events.subscribe( (event: RouterEvent) => {
//   if (event instanceof NavigationStart) {
//     this.store.dispatch(new ui.ToggleSideMenuAction());
//   }
// })

export function reducer(state = initialState, action: UnsafeAction): UiState {
  switch(action.type){
    case '@ngrx/store/init': {
      return {
        ...state,
        windowSize: {
          height: window.screen.height,
          width: window.screen.width
        },
        showMobileMenu: window.screen.width <= breakpoint,
        sideMenuOpened: window.screen.width > breakpoint
      }
    }
    case ui.UiActionTypes.RESIZE_WINDOW: {
      const { height, width } = action.payload
      return {
        ...state,
        windowSize: {
          height,
          width,
        },
        showMobileMenu: width <= breakpoint,
        sideMenuOpened: width > breakpoint
      }
    }
    case ui.UiActionTypes.HAS_SIDE_MENU: {
      return {
        ...state,
        hasSideMenu: true
      }
    }
    case ui.UiActionTypes.REMOVE_SIDE_MENU: {
      return {
        ...state,
        hasSideMenu: false
      }
    }
    case ui.UiActionTypes.TOGGLE_SIDE_MENU: {
      return {
        ...state,
        sideMenuOpened: !state.sideMenuOpened
      }
    }
    case ui.UiActionTypes.CLOSE_SIDE_MENU: {
      return {
        ...state,
        sideMenuOpened: false
      }
    }
    default:
      return state
  }
}

// getters
export const getWindowSize = (state: UiState) => state.windowSize;
export const getShowMobileMenu = (state: UiState) => state.showMobileMenu;
export const getHasSideMenu = (state: UiState) => state.hasSideMenu;
export const isSideMenuOpened = (state: UiState) => state.sideMenuOpened;
