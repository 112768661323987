
import { ActionTypes } from '../actions/my-customers.actions';
import { UnsafeAction } from '@nuso/common/util-state';
import { Customer } from '@nuso/orgs/types';

export class State {
  customers: Customer[];
  loading: boolean;
  filter?: string;
}

export const initialState: State = {
  customers: null,
  loading: false,
  filter: null
}

export function reducer(state = initialState, action: UnsafeAction ) {
  switch (action.type) {
    case ActionTypes.LOAD_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.LOAD_RETURNED:
      return {
        ...state,
        loading: false,
        customers: action.payload
      };
    case ActionTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false
      };
    case ActionTypes.UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case ActionTypes.RESET_CUSTOMERS:
      return {...initialState};

    default:
        return state;
  }
}

export const getLoading = (state: State) => state.loading;
export const getMyCustomers = (state: State) => state.customers;
export const getFiltered = (state: State) => {
  if (!state.filter) {
    return state.customers;
  }

  const lcFilter = state.filter.toLowerCase();
  return state.customers.filter(c =>
    c.name.toLowerCase().indexOf(lcFilter) !== -1
    || c.accountNumber.indexOf(lcFilter) !== -1);

};

