
import {Apollo, QueryRef} from 'apollo-angular';
import {WatchQueryOptions, MutationOptions, QueryOptions, ApolloQueryResult} from '@apollo/client/core';
import { of as observableOf,  Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { NotificationService } from '@nuso/common/util-errors';

import { map, catchError, filter, switchMap, tap, takeWhile, finalize } from 'rxjs/operators';


import { HttpErrorResponse } from '@angular/common/http';
import { defaults } from 'lodash';

import { NETWORK_STATUS_QUERY, NetworkStatus } from './network-status/network-status.model';
export interface ExtendedWatchQueryOptions extends WatchQueryOptions {
  defaultResponse?: any;
  rethrowErrors?: boolean;
  msgPrefix?: string;
}

export interface GraphErrorObject {
  code: string;
  message: string;
}

// Typically we just do one query at a time. If that is the case
// return data.whatever
// If we are doing multiple queries, return data and leave it up
// to the user of the watchQuery to drill in.
function getResultFromData(data: any, options?: WatchQueryOptions) {
  const keys = Object.keys(data);
  // const queryDefn: OperationDefinitionNode = <OperationDefinitionNode>options.query.definitions[0];
  // return data[queryDefn.name.value];

  return keys.length === 1 ? data[keys[0]] : data;
}

export function getMutationResult(result) {
  return (result && result.data) ? getResultFromData(result.data) : result;
}

export function graphErrorMessage(err) {
  if (!err) {
    return null;
  };

  if (err.message) {
    return err.message.replace(/^GraphQL error: /, '');
  }
  return err.message;
}

export function graphErrorObject(err: any): GraphErrorObject {
  return {
    code: err?.graphQLErrors ? err.graphQLErrors[0]?.extensions?.code || 'UNKNOWN_ERROR' : 'UNKNOWN_ERROR',
    message: graphErrorMessage(err)
  }
}

interface GraphRefetchPromiseCallbacks<T> {
  resolve: (value: ApolloQueryResult<T>) => void;
  reject: (reason?: any) => void;
}

export class GraphApiQueryRef<T> {
  // Need this in case an error happens stopping the inner
  // getResult Observable, and then refetch is called.
  // We'd like to startup a new watchQuery in this case.
  restartRefetch$ = new BehaviorSubject<GraphRefetchPromiseCallbacks<T> | null>(null);

  constructor(public queryRef: QueryRef<T>, public options: ExtendedWatchQueryOptions, private api: GraphApiService) {
  }

  refetch(variables?: any) {
    if (this.queryRef) {
      return this.queryRef.refetch(variables);
    } else {
      return new Promise((resolve, reject) => {
        this.restartRefetch$.next({ resolve, reject });
      });
    }
  }

  getResult(refetchPromiseCallBacks?: GraphRefetchPromiseCallbacks<T> | null) {
    return this.queryRef.valueChanges
      .pipe(
        filter(result => !!result.data),
        tap(result => {
          if (refetchPromiseCallBacks) {
            refetchPromiseCallBacks.resolve(result);
          }
        }),
        map(result => getResultFromData(result.data, this.options)),
        catchError((err) => {
          if (refetchPromiseCallBacks) {
            refetchPromiseCallBacks.reject(err);
          }
          return this.api.handleError(err, this.options);
        }),
        finalize(() => this.queryRef = null)
      );
  }

  fetchMore(options) {
    return this.queryRef.fetchMore(options);
  }

  watchPoll() {
    const defaultOptions = {
      pollInterval: 10000,
      fetchPolicy: 'cache-and-network',
    };

    const connectedToNetwork$ = this.api.apollo.watchQuery({ query: NETWORK_STATUS_QUERY } )
      .valueChanges.pipe(
        map(result => getResultFromData(result.data)),
        takeWhile((networkStatus: NetworkStatus) => !networkStatus.isAuthenticationError),
        filter((networkStatus: NetworkStatus) => networkStatus.isConnected) // && !networkStatus.isAuthenticationError)
      );

    return combineLatest([connectedToNetwork$, this.restartRefetch$])
      .pipe(
        switchMap(([networkState, refetchPromiseCallbacks]) => {
          this.queryRef = this.api.apollo.watchQuery<T>(defaults(this.options, defaultOptions));
          return this.getResult(refetchPromiseCallbacks);
        })
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class GraphApiService {
  constructor(
    public apollo: Apollo,
    private notificationService: NotificationService
  ) {
  }

  handleError(error, options: ExtendedWatchQueryOptions) {
    // Ignore lost network errors, these are handled globaly
    // But return what is in the cache
    const defaultResponse = options.defaultResponse !== undefined ? options.defaultResponse : null;
    if (error.networkError instanceof HttpErrorResponse && error.networkError.status === 0) {
      const cachedResponse = this.apollo.client.readQuery(options);
      return cachedResponse
        ? observableOf(getResultFromData(cachedResponse, options))
        : observableOf(defaultResponse);
    } else {
      if (options.rethrowErrors) {
        return throwError(() => error);
      }
      else {
        this.notificationService.addGraphqlError(error, options.msgPrefix);
        return observableOf(defaultResponse);
      }
    }
  }

  watchQueryRef<T>(options: ExtendedWatchQueryOptions): GraphApiQueryRef<T> {
    const queryRef = this.apollo.watchQuery<T>(options);

    return new GraphApiQueryRef(queryRef, options, this);
  }

  watchQuery<T>(options: ExtendedWatchQueryOptions): Observable<T> {
    const query = this.watchQueryRef<T>(options);
    return query.getResult();
  }

  pollQueryRef<T>(options: ExtendedWatchQueryOptions): GraphApiQueryRef<T> {
    return new GraphApiQueryRef(null, options, this);
  }

  pollQuery<T>(options: ExtendedWatchQueryOptions): Observable<T> {
    const query = this.pollQueryRef(options);
    return query.watchPoll();
  }

  query(options: QueryOptions) {
    return this.apollo.query(options);
  }

  mutate(options: MutationOptions) {
    return this.apollo.mutate({
      ...options,
      context: {
        headers: {
          'portal-path': window.location.pathname
        }
      }
    });
  }

  readQuery<T>(options: ExtendedWatchQueryOptions): T {
    return this.apollo.client.readQuery(options);
  }

  writeQuery(options) {
    this.apollo.client.writeQuery(options);
  }
}
