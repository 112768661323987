import { Action } from '@ngrx/store';

import { type } from '@nuso/common/util-state';
import { Customer } from '@nuso/orgs/types';

export const ActionTypes = {
  ATTEMPT_LOGIN: type('[Login] Attempt Login'),
  ATTEMPT_LOGIN_WITH_RESET_TOKEN: type('[Login] Attempt password reset token login'),
  ATTEMPT_LOGIN_WITH_NEC_TOKEN: type('[Login] Attempt NEC token login'),
  IMPERSONATE_USER: type('[Login] Impersonate User attempt'),
  STOP_IMPERSONATING_USER: type('[Login] Stop impersonating user'),
  REFRESH_TOKEN: type('[Login] Refresh Login Token'),
  RECEIVE_SESSION_INFO: type('[Login] Receive Session Info'),
  FAIL_LOGIN: type('[Login] Fail Login'),
  FAIL_NEC_LOGIN: type('[Login] Fail NEC Login'),
  LOGOUT: type('[Login] Logout'),
  SET_REDIRECT_URL: type('[Login] Set Redirect URL'),
  SESSION_EXPIRED: type('[Login] Session Expired'),
  RESET_REDIRECT_URL: type('[Login] Reset Redirect URL'),
  SET_CURRENT_CUSTOMER: type('[Login] Set Current Customer'),
  SET_RETURNED_CURRENT_CUSTOMER: type('[Login] Set Returned Current Customer'),
  SET_CURRENT_CUSTOMER_REDIRECT_URL: type('[Login] Set redirect url when set current customer'),
  SET_FAILED_CURRENT_CUSTOMER: type('[Login] Set Failed Current Customer'),
  UPDATE_CURRENT_CUSTOMER: type('[Login] Update who the customer is with custom redirect.')
};


export class AttemptLoginAction implements Action {
  type = ActionTypes.ATTEMPT_LOGIN;

// TODO: type out LoginCredentials and use instead of any
  constructor(public payload: any) {}
}

export class AttemptLoginWithResetTokenAction implements Action {
  type = ActionTypes.ATTEMPT_LOGIN_WITH_RESET_TOKEN;

  constructor(public payload: string) {}
}

export interface LoginWithNecTokenInput {
  token: string;
  redirectUrl: string;
}

export class AttemptLoginWithNecTokenAction implements Action {
  type = ActionTypes.ATTEMPT_LOGIN_WITH_NEC_TOKEN;

  constructor(public payload: LoginWithNecTokenInput) {}
}

export class ImpersonateUserAction implements Action {
  type = ActionTypes.IMPERSONATE_USER;

  // userId: number
  constructor(public payload: number) {
  }
}

export class StopImpersonatingUserAction implements Action {
  type = ActionTypes.STOP_IMPERSONATING_USER;

  constructor() {}
}

export class RefreshLoginTokenAction implements Action {
  type = ActionTypes.REFRESH_TOKEN;

  constructor(public payload: string) { }
}

export class ReceiveSessionInfoAction implements Action {
  type = ActionTypes.RECEIVE_SESSION_INFO;

// TODO: type out LoginResponse and use instead of any
  constructor(public payload: any) {
    // console.trace('RECEIVE_SESSION_INFO', payload);
  }
}

export class FailLoginAction implements Action {
  type = ActionTypes.FAIL_LOGIN;

  constructor(public payload: string) {}
}

export class FailNECLoginAction implements Action {
  type = ActionTypes.FAIL_NEC_LOGIN;

  constructor(public payload: string) {}
}

export class LogoutAction implements Action {
  type = ActionTypes.LOGOUT;

  constructor() {}
}

export class SessionExpiredAction implements Action {
  type = ActionTypes.SESSION_EXPIRED;

  constructor() {}
}

export class SetRedirectUrlAction implements Action {
  type = ActionTypes.SET_REDIRECT_URL;

  constructor(public payload: string) {}
}

export class ResetRedirectUrlAction implements Action {
  type = ActionTypes.RESET_REDIRECT_URL;

  constructor() {}
}

export class SetCurrentCustomerRedirectUrlAction implements Action {
  type = ActionTypes.SET_CURRENT_CUSTOMER_REDIRECT_URL;

  constructor(public payload: string | null) {}
}

export class SetCurrentCustomerAction implements Action {
  type = ActionTypes.SET_CURRENT_CUSTOMER;

  constructor(public payload: number) {}
}

export class SetReturnedCurrentCustomerAction implements Action {
  type = ActionTypes.SET_RETURNED_CURRENT_CUSTOMER;

  constructor(public payload: Customer) {}
}

export class UpdateCurrentCustomerAction implements Action {
  type = ActionTypes.UPDATE_CURRENT_CUSTOMER

  constructor(public payload: Customer) {}
}

export class SetFailedCurrentCustomerAction implements Action {
  type = ActionTypes.SET_FAILED_CURRENT_CUSTOMER;

  constructor(public payload: any) {}
}


export type Actions
  = AttemptLoginAction
  | AttemptLoginWithResetTokenAction
  | AttemptLoginWithNecTokenAction
  | ImpersonateUserAction
  | StopImpersonatingUserAction
  | RefreshLoginTokenAction
  | ReceiveSessionInfoAction
  | FailLoginAction
  | LogoutAction
  | SessionExpiredAction
  | SetRedirectUrlAction
  | SetCurrentCustomerAction
  | SetReturnedCurrentCustomerAction
  | SetFailedCurrentCustomerAction
  | FailNECLoginAction
;
