import { SelectOption } from '@nuso/forms/data-access';

export interface AppEnvironment {
  envName: string;
  production: boolean;
  sentry: string | null;
  showDebug: boolean;
  appName: string;
  ssoEnabled?: boolean;
  ssoLoginUrl?: string;
  userPortalUrl?: string;
  statusPageIoEmbedUrl: string;
}

export interface NecEnvironment {
  necSsoEnabled: boolean;
  necSsoLoginUrl: string | null;
}

export interface PortalAppFeatures {
  customerTestingEnabled: boolean;
  nocEnabled: boolean;
  eFaxEnabled: boolean;
  ordersEnabled: boolean;
  cdrReportingEnabled: boolean;
  billingEnabled: boolean;
  portingEnabled: boolean;
  supportEnabled: boolean;
  liveTeamworkSupportEnabled: boolean;
  salesforceSupportEnabled: boolean;
  archivedSupportEnabled: boolean;
  billableEventsEnabled: boolean;
  issuesEnabled: boolean;
  statusPageEnabled: boolean;
  numberMovingEnabled: boolean;
  bandwidthSyncingEnabled: boolean;
  commportalUrl: string;
  isTestDeleteMeta: boolean;
  bridgeZoomAuthUrl: string;

  supportedCustomerFeatures: string[];
  serviceOfferingOptions: SelectOption[];
  userRoleOptions: SelectOption[];
}

export interface PortalAppEnvironment extends AppEnvironment, NecEnvironment, PortalAppFeatures {
}

export const isProductionToken = 'isProduction';
export const appEnvironmentToken = 'appEnvironment';
export const necEnvironmentToken = 'necEnvironment';
export const portalAppFeaturesToken = 'portalAppFeatures';