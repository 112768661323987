import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql/error';

import { map } from 'lodash';

import { NetworkStatusUpdateService } from './network-status/network-status-update.service';


function hasAuthenticationError(graphQLErrors: GraphQLError[]): boolean {
  return hasErrorCode(graphQLErrors, 'UNAUTHENTICATED');
}

function hasErrorCode(graphQLErrors: GraphQLError[], errorCode: string): boolean {
  return !!graphQLErrors.find((error: GraphQLError) => error?.extensions?.code === errorCode);
}

export function createGraphqlErrorLink(networkService: NetworkStatusUpdateService, isProduction: boolean) {
  return onError(({ graphQLErrors, networkError }) => {
    console.log({ isProduction })
    if ( graphQLErrors ) {
      if ( !isProduction ) {
        console.log('graphQLErrors', graphQLErrors);
      }
      if ( hasAuthenticationError(<GraphQLError[]>graphQLErrors) ) {
        console.log('auth error', graphQLErrors)
        networkService.updateAuthenticationError(true);
        //this.store.dispatch(new SessionExpiredAction());
      }
    }

    if ( networkError ) {
      if ( (<any>networkError).status === 0 ) {
        networkService.updateConnected(false)
        // this.store.dispatch(new LostNetworkAction())
      } else {
        const error = (<any>networkError).error;
        if (error && error.errors) {
          networkError.message = networkError.message + ' - ' + collectErrorMessages(error)
        }

        if ( !isProduction ) {
          console.log('network error', networkError);
        }
      }
    }
  })
}

function collectErrorMessages(error: any) {
  return map(error.errors, 'message').join('\n')
}

