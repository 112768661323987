import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { TabSessionService } from './tab-session.service';
import { filter, tap } from 'rxjs';

@Injectable()
export class XTabIdInterceptor implements HttpInterceptor {

  constructor(private tabSessionService: TabSessionService) {}

  // Sets the x-tab-id header on all outgoing requests
  // We will conditionally set a x-bootstrap-tab-id header
  // We only need to send this header once, and once the tab session data is
  // bootstrapped on the backend, we no longer need to send the header (even
  // though there is no harm in doing so). But no reason to send the extra
  // header if not needed.
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const tabId = this.tabSessionService.getTabId();
    const bootstrapTabId = this.tabSessionService.getBoostrapTabId();

    let headers = req.headers.set('X-Tab-Id', tabId);
    if (bootstrapTabId) {
      headers = headers.set('X-Bootstrap-Tab-Id', bootstrapTabId);
    }

    const modifiedReq = req.clone({
      headers
    });

    return next.handle(modifiedReq)
      .pipe(
        tap(() => bootstrapTabId && this.tabSessionService.setIsBootstrapped())
      );
  }

}