
import { gql } from 'apollo-angular';
import { of as observableOf, Observable } from 'rxjs';

import { take, map, tap, catchError, first, filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ApiService, GraphApiService } from '@nuso/common/data-access-api';
import { get } from 'lodash';



import { CurrentUser } from '@nuso/auth/types';
import { LOGIN_STATUS_QUERY, LoginStatus, LoginStateService } from './login-state.service';
import { NotificationService } from '@nuso/common/util-errors';

const ForgotPasswordMutation = gql`
mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    fromEmail
  }
}
`;

const loginResponseFields = `
  id
  name
  email
  status
  roles
  createdAt
  updatedAt
  salesforceId
  allPermissions
  impersonatorId
  currentCustomer {
    id
    name
    displayName
    accountNumber
    mainServiceKey
    regionCode
    timezone
    status
  }
`;

const loginMutation = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ${loginResponseFields}
    }
  }
`;


const RefreshLoginTokenMutation = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      ${loginResponseFields}
    }
  }
`;

const loginWithResetTokenMutation = gql`
  mutation loginWithResetToken($token: String!) {
    loginWithResetToken(token: $token) {
      ${loginResponseFields}
    }
  }
`;

const loginWithNecTokenMutation = gql`
  mutation loginWithNecToken($token: String!) {
    loginWithNecToken(token: $token) {
      ${loginResponseFields}
    }
  }
`;

const loginWithAuthTokenMutation = gql`
  mutation loginWithAuthToken($token: String!, $appName: String!) {
    loginWithAuthToken(token: $token, appName: $appName) {
      ${loginResponseFields}
    }
  }
`;

const impersonateUserMutation = gql`
  mutation impersonateUser($userId: Int!) {
    impersonateUser(userId: $userId) {
      ${loginResponseFields}
    }
  }
`;

const stopImpersonatingUserMutation = gql`
  mutation stopImpersonatingUser {
    stopImpersonatingUser {
      ${loginResponseFields}
    }
  }
`;

const changePasswordMutation = gql`
  mutation changeUserPassword($userId: Int!, $password: String!) {
    changeUserPassword(userId: $userId, password: $password) {
      id
    }
  }
`;

const currentUserQuery = gql`
  query currentUser {
    currentUser {
      ${loginResponseFields}
      type
      organization
      salesforceId

      isAdmin
      isSuperuser
      isPartner
      isCustomerAdmin
      isCustomer
      isEndUser
      isNusoAdmin
      isInternalUser
      canAccessSubOrgs
      canAccessOrg
      canAccessOrgOnly

      myApps {
        managementPortal { id url }
        userPortal { id url }
        ipfax {
          id
          name
          faxNumber
          secureFax
          inboundTotal
          inboundUnread
          inboundPages
          outboundTotal
          outboundPages
        }
        voicemail {
          id
          name
          vmboxNumber

          stats {
            id
            totalCount
            totalUnreadCount
            inboxCount
            inboxUnreadCount
            trashCount
            trashUnreadCount
            archiveCount
            archiveUnreadCount
            sizeLimitMB
            totalSizeKB
          }
        }
      }

      nameAndOrganization @client
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private api: ApiService,
    private graphApi: GraphApiService,
    private loginState: LoginStateService,
    private notificationService: NotificationService,
  ) { }

  setRedirectUrl(url: string) {
    this.loginState.setRedirectUrl(url);
  }

  login(username: string, password: string) {
    this.loginState.attemptLogin();
    return this.graphApi.mutate({
      mutation: loginMutation,
      variables: { username, password }
    }).pipe(
      map(results => results.data.login),
      tap(loginSession => this.loginState.receiveSessionInfo(loginSession)),
      catchError(error => {
        this.loginState.failLogin();
        const fullServerMessage = get(error, 'message', error);
        if (fullServerMessage.indexOf('Failed to login') !== -1) {
          this.notificationService.addError('Invalid username or password');
        } else {
          this.notificationService.addError(error);
        }
        return observableOf(null);
      })
    )
  }

  logout() {
    return this.api.post('/api/logout', {})
      .pipe(
        tap(() => this.loginState.logout()),
        first()
      )
  }

  impersonateUser(userId: number) {
    return this.graphApi.mutate({
      mutation: impersonateUserMutation,
      variables: { userId }
    }).pipe(
      map(results => results.data.impersonateUser),
      first()
    )
  }

  stopImpersonateUser() {
    return this.graphApi.mutate({
      mutation: stopImpersonatingUserMutation,
    }).pipe(
      map(results => results.data.stopImpersonatingUser),
      first()
    )
  }

  isImpersonated(): boolean {
    const currentUser: any = this.graphApi.apollo.client.cache.readQuery({
      query: gql`query currentUser { currentUser { id impersonatorId} }`,
    });
    return !!(currentUser && currentUser.currentUser && currentUser.currentUser.impersonatorId);
  }

  refreshToken(token: string) {
    if (!token) {
      return observableOf({});
    }

    return this.graphApi.mutate({
      mutation: RefreshLoginTokenMutation,
      variables: {
        token
      }
    }).pipe(
      map(results => results.data.refreshToken),
      first()
    )
  }

  forgotPassword(email: string) {
    return this.graphApi.mutate({
      mutation: ForgotPasswordMutation,
      variables: {
        email
      }
    }).pipe(
      map(results => results.data.forgotPassword),
      take(1)
    );
  }

  changePassword(userId: number, password: string) {
    return this.graphApi.mutate({
      mutation: changePasswordMutation,
      variables: {
        userId, password
      }
    }).pipe(
      map(results => results.data.changePassword),
      take(1)
    );
  }

  loginWithResetToken(token: string) {
    return this.graphApi.mutate({
      mutation: loginWithResetTokenMutation,
      variables: {
        token
      }
    }).pipe(
      map(results => results.data.loginWithResetToken),
      take(1)
    );
  }

  // For lab – Use this URL and it will log you in as old Paul with full access
  // This is instead of typing in a username and password. Not sure how long the session lasts
  // -- https://ubportal.necproj.com/uuddlrlr/paul.matte@necam.com
  loginWithNecToken(token: string) {
    return this.graphApi.mutate({
      mutation: loginWithNecTokenMutation,
      variables: {
        token
      }
    }).pipe(
      map(results => results.data.loginWithNecToken),
      take(1)
    );
  }

  loginWithAuthToken(token: string, appName: string) {
    return this.graphApi.mutate({
      mutation: loginWithAuthTokenMutation,
      variables: {
        token,
        appName
      }
    }).pipe(
      map(results => results.data.loginWithAuthToken),
      take(1)
    );
  }

  currentUser(): Observable<CurrentUser> {
    return this.graphApi.watchQuery<CurrentUser>({
      query: currentUserQuery
    })
  }

  // pair this with the poll query
  // i.e. if  the app is doing a poll query on load,
  // you can use this on your other pages
  cachedCurrentUser(): Observable<CurrentUser> {
    return this.graphApi.watchQuery<CurrentUser>({
      query: currentUserQuery,
      fetchPolicy: 'cache-only'
    })
  }

  pollCurrentUser(pollInterval = 30000): Observable<CurrentUser> {
    return this.graphApi.pollQuery({
      query: currentUserQuery,
      pollInterval
    })
  }


  setSsoRedirectUrl(redirectUrl: string) {
    window.localStorage.setItem('ssoRedirectUrl', redirectUrl);
  }

  resetSsoRedirectUrl() {
    window.localStorage.removeItem('ssoRedirectUrl');
  }

  ssoRedirectUrl() {
    return window.localStorage.getItem('ssoRedirectUrl') || '/';
  }

  loginStatus() {
    return this.graphApi.watchQuery<LoginStatus>({
      query: LOGIN_STATUS_QUERY
    })
  }

  setSsoLoginFlag() {
    console.log('flag set')
    window.sessionStorage.setItem('ssoLogin', '1');
  }

  resetSsoLoginFlag() {
    console.log('flag reset')
    window.sessionStorage.removeItem('ssoLogin');
  }

  ssoLoginFlag() {
    console.log('flag get')
    return window.sessionStorage.getItem('ssoLogin') || null;
  }

  completedLoginStatus() {
    return this.loginStatus()
      .pipe(
        tap(status => console.log(status)),
        filter((status) => !status.pending),
        first()
      );
  }

}
