import { Action } from '@ngrx/store';

import { type } from '@nuso/common/util-state';

export const ActionTypes = {
  LOAD_PAGE: type('[Page] Load'),
  LOAD_PREVIOUS_PAGE: '[Page] Load Previous',
  PAGE_LOADING: type('[Page] Loading'),
  PAGE_LOADED: type('[Page] Loaded'),
  PAGE_CANCELLED: type('[Page] Cancelled'),
  PAGE_ERRORED: type('[Page] Errored')
};

export class PageLoadingAction implements Action {
  type = ActionTypes.PAGE_LOADING;

  constructor(public payload: string) { }
}

export class PageLoadedAction implements Action {
  type = ActionTypes.PAGE_LOADED;

  constructor(public payload: string) { }
}

export class PageCancelledAction implements Action {
  type = ActionTypes.PAGE_CANCELLED;

  constructor(public payload: string) { }
}

export class PageErroredAction implements Action {
  type = ActionTypes.PAGE_ERRORED;

  constructor(public payload: any) { }
}

export class LoadPageAction implements Action {
  type = ActionTypes.LOAD_PAGE;

  constructor(public payload: string) { }
}

export class LoadPreviousPageAction implements Action {
  type = ActionTypes.LOAD_PREVIOUS_PAGE;

  constructor(public payload: any) { }
}

export type Actions
  = LoadPageAction
  | LoadPreviousPageAction
  | PageLoadingAction
  | PageLoadedAction
  | PageCancelledAction
  | PageErroredAction;
