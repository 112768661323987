import { createSelector } from 'reselect';
import { ActionReducerMap } from '@ngrx/store';
import * as _ from 'lodash';

import * as fromLogin from './login';
import * as fromPage from './page';
import * as fromMyCustomers from './my-customers.reducers';
import * as fromUI from './ui.reducers';

export interface State {
  ui: fromUI.UiState;
  page: fromPage.State;
  loginStatus: fromLogin.State;
  myCustomers: fromMyCustomers.State;
}

export function createReducers(): ActionReducerMap<State> {
  return {
    ui: fromUI.reducer,
    page: fromPage.reducer,
    loginStatus: fromLogin.reducer,
    myCustomers: fromMyCustomers.reducer,
  };
}


export const getState = (state: State) => state;

export const getUiState = (state: State) => state.ui;
export const getShowMobileMenu = createSelector(getUiState, fromUI.getShowMobileMenu);
export const getWindowSize = createSelector(getUiState, fromUI.getWindowSize);
export const getHasSideMenu = createSelector(getUiState, fromUI.getHasSideMenu);
export const getIsSideMenuOpen = createSelector(getUiState, fromUI.isSideMenuOpened);

export const getPageState = (state: State) => state.page;
export const getPageLoading = createSelector(getPageState, fromPage.getLoading);
export const getPagePath = createSelector(getPageState, fromPage.getCurrent);
export const getPagePrevious = createSelector(getPageState, fromPage.getPrevious);
export const getRootPath = createSelector(getPagePath, (path) => (path || '/').split('/')[1] || '/');

export const getLoginState = (state: State) => state.loginStatus;
export const getSessionInfo = createSelector(getLoginState, fromLogin.getSessionInfo);
export const getLoginRoles = createSelector(getLoginState, fromLogin.getRoles);
export const getLoginPermissions = createSelector(getLoginState, fromLogin.getPermissions);
export const getLoginRedirectUrl = createSelector(getLoginState, fromLogin.getRedirectUrl);
export const geCurrentCustomerLoginRedirectUrl = createSelector(getLoginState, fromLogin.getCurrentCustomerRedirectUrl);
export const getLoginPending = createSelector(getLoginState, fromLogin.getLoginPending);
export const getLoginToken = createSelector(getLoginState, fromLogin.getId);
export const getLoginUsername = createSelector(getLoginState, fromLogin.getUsername);
export const getIsLoggedIn = createSelector(getLoginState, fromLogin.getIsLoggedIn);
export const getLoginUserId = createSelector(getLoginState, fromLogin.getId);
export const getCurrentCustomerId = createSelector(getLoginState, fromLogin.getCurrentCustomerId);
export const getCurrentCustomerMainServiceKey = createSelector(getLoginState, fromLogin.getCurrentCustomerMainServiceKey);
export const getCurrentCustomerIsSupportConfigured = createSelector(getLoginState, fromLogin.getCurrentCustomerIsSupportConfigured);
export const getCurrentCustomer = createSelector(getLoginState, fromLogin.getCurrentCustomer);
export const getCurrentCustomerName = createSelector(getCurrentCustomer, (currCust) => currCust ? currCust.displayName : null);

export const getMyCustomersState = (state: State) => state.myCustomers;
export const getMyCustomers = createSelector(getMyCustomersState, fromMyCustomers.getMyCustomers);
export const getFilteredCustomers = createSelector(getMyCustomersState, fromMyCustomers.getFiltered);
