import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { ICrudModel } from '@nuso/common/types';
import { CrudActionTypes } from './crud.actions';
import { UnsafeAction } from '../util';

export interface ICrudState {
  loaded: boolean;
  loading: boolean;
  loadError: any;
  ids: string[];
  entities: any;
  creating: any;
  created: boolean;
  createError: any;
  saving: any;
  saved: boolean;
  saveError: any;
  deleting: any;
  deleted: boolean;
  deleteError: any;
  filter?: string;
  current_id: string;
  updating: any;
  updated: boolean;
  updateError: any;
}

export interface CrudState<T> extends ICrudState {
  entities: { [id: string]: T };
  creating: T;
  saving: T;
}

export const initialCrudState: ICrudState = {
  loaded: false,
  loading: false,
  loadError: '',
  ids: [],
  entities: {},
  creating: null,
  created: false,
  createError: '',
  saving: null,
  saved: false,
  saveError: '',
  deleting: null,
  deleted: false,
  deleteError: '',
  current_id: null,
  updating: null,
  updated: false,
  updateError: '',
};

export function crudReducer<T extends ICrudModel>(actionTypes: CrudActionTypes, state, action: UnsafeAction) {
  switch (action.type) {
    case actionTypes.LOAD_ALL: {
      return {
        ...state,
        loading: true,
        loadError: '',
        loaded: false
      };
    }

    case actionTypes.LOAD_ALL_SUCCESS: {
      const results = action.payload;
      const ids = results.map(o => o.id);
      const entities = results.reduce((newEntities: { [id: number]: T }, o: T) => {
        return Object.assign(newEntities, {
          [o.id]: o
        });
      }, {});

      return {
        ...state,
        loaded: true,
        loading: false,
        loadError: false,
        ids,
        entities,
      };
    }

    case actionTypes.LOAD_ALL_FAIL: {
      return {...initialCrudState, loadError: action.payload};
    }

    case actionTypes.UPDATE_FILTER: {
      return {...state, filter: action.payload };
    }

    case actionTypes.CREATE: {
      return {
        ...state,
        creating: action.payload,
        created: false,
        createError: ''
      };
    }
    case actionTypes.UPDATE: {
      return {
        ...state,
        updating: action.payload,
        updated: false,
        updateError: ''
      };
    }
    case actionTypes.CREATED: {
      return {
        ...state,
        creating: null,
        created: true,
        createError: '',
        ids: [ action.payload.id, ...state.ids ],
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      };
    }
    case actionTypes.UPDATED: {
      return {
        ...state,
        updating: null,
        updated: true,
        updateError: '',
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload
        }
      };
    }
    case actionTypes.CREATE_FAILED: {
      return {
        ...state,
        creating: null,
        created: false,
        createError: action.payload
      };
    }
    case actionTypes.UPDATE_FAILED: {
      return {
        ...state,
        updating: null,
        updated: false,
        updatedError: action.payload
      };
    }
    case actionTypes.DELETE: {
      return {
        ...state,
        deleting: action.payload,
        deleted: false,
        deleteError: ''
      };
    }
    case actionTypes.DELETE_FAILED: {
      return {
        ...state,
        deleting: null,
        deleted: false,
        deleteError: action.payload
      };
    }
    case actionTypes.DELETED: {
      return {
        ...state,
        deleting: null,
        deleted: true,
        deleteError: '',
        ids: state.ids.filter(id => id !== action.payload),
        entities: _.omit(state.entities, [action.payload])
      };
    }
    case actionTypes.SET_CURRENT: {
      return {
        ...state,
        current_id: action.payload
      };
    }

    default:
      return state;
  }
}

export function createCrudSelectors<S extends ICrudState>() {
  const getLoaded = (state: S) => state.loaded;
  const getLoading = (state: S) => state.loading;
  const getLoadError = (state: S) => state.loadError;
  const getIds = (state: S) => state.ids;
  const getEntities = (state: S) => state.entities;
  const getEntity = (id: string) => (state: S) => state.entities[id];
  const getFilter = (state: S) => state.filter;
  const getCurrentId = (state: S) => state.current_id;
  const getCreating = (state: S) => !!state.creating;
  const getCreated = (state: S) => state.created;
  const getCreateError = (state: S) => state.createError;
  const getUpdating = (state: S) => !!state.updating;
  const getUpdated = (state: S) => state.updated;
  const getUpdateError = (state: S) => state.updateError;
  const getDeleting = (state: S) => !!state.deleting;
  const getDeleted = (state: S) => state.deleted;
  const getDeleteError = (state: S) => state.deleteError;
  const getAll = createSelector(getIds, getEntities, (ids, entities) => {
    return ids.map(id => entities[id]);
  });

  const getCurrent = createSelector(getCurrentId, getEntities, (id, entities) => {
    return id !== null ? entities[id] : null;
  });

  return {
    getLoaded,
    getLoading,
    getLoadError,
    getIds,
    getEntities,
    getEntity,
    getFilter,
    getAll,
    getCurrentId,
    getCurrent,
    getCreating,
    getCreated,
    getCreateError,
    getUpdating,
    getUpdated,
    getUpdateError,
    getDeleting,
    getDeleted,
    getDeleteError
  };
}
